import React, { FC, useEffect } from "react";
import CollectionCard from "components/CollectionCard";
import HeaderFilterSection from "components/HeaderFilterSection";
import { Collection } from "helpers/types";
import CollectionProject from "components/CollectionProject";
import { doGet } from "helpers/api_helper";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Link } from "react-router-dom";
import ButtonOutline from "shared/Button/ButtonOutline";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridFeatureNFTProps { }

const SectionGridFeatureNFT: FC<SectionGridFeatureNFTProps> = () => {
  const [data, setData] = React.useState<Collection[]>([]);
  const [loadedData, setLoadedData] = React.useState<Collection[]>([]);
  const [tabActive, setTabActive] = React.useState("Generative");
  const [isOpen, setIsOpen] = React.useState(false);
  const [curatedCollection, setCuratedCollection] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [numAssetsDisplayed, setNumAssetsDisplayed] = React.useState<number>(4);
  const [numAssetsLoaded, setNumAssetsLoaded] = React.useState<number>(0);

  const handleTabChange = (tab: string) => {
    if (tabActive !== tab) {
      setTabActive(tab);
      setNumAssetsDisplayed(4);
      setLoadedData([]);
      setNumAssetsLoaded(0);
    }
  };

  useEffect(() => {
    let endpoint = `/api/collection/search?curated=${curatedCollection}&name=${searchValue}&skip=${numAssetsLoaded}&limit=${numAssetsDisplayed}`;
    if (tabActive !== "All Collections") {
      endpoint = `/api/collection/search?tags=${tabActive}&curated=${curatedCollection}&name=${searchValue}&skip=${numAssetsLoaded}&limit=${numAssetsDisplayed}`;
    }

    doGet(endpoint)
      .then((response) => {
        setLoadedData([...loadedData, ...response.data]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [
    tabActive,
    curatedCollection,
    searchValue,
    numAssetsDisplayed,
    numAssetsLoaded,
  ]);

  useEffect(() => {
    setData(loadedData);
  }, [loadedData]);

  return (
    <div className="nc-SectionGridFeatureNFT relative">
      <HeaderFilterSection onTabChange={handleTabChange} />
      <div
        className={`grid gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 min-h-[420px]`}
      >
        {data.slice(0, numAssetsDisplayed).map((asset, index) => (
          <CollectionProject
            key={asset.id}
            id={asset.id}
            name={asset.name}
            cardpath={asset.cardpath}
            owner={asset.owner}
            cardtype={asset.cardtype}
            curated={asset.curated}
            avatarpath={asset.avatarpath}
            tags={asset.tags}
            description={asset.description}
            path={asset.path}
            policy={asset.policy}
            className="fade-in "
            floorPrice={asset.floorPrice}
          />
        ))}
      </div>
      <div className="flex justify-center items-center m-12">
        <Link to={`/discover?tag=${tabActive}`}>
          <ButtonSecondary className="">View All {tabActive} Collections</ButtonSecondary>
        </Link>
      </div>
    </div>
  );
};

export default SectionGridFeatureNFT;
