import React, { FC, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionSliderCollections from "components/SectionSliderCollections";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import HeaderFilterSearchPage from "components/HeaderFilterSearchPage";
import Input from "shared/Input/Input";
import ButtonCircle from "shared/Button/ButtonCircle";
import CollectionProject from "components/CollectionProject";
import { Collection } from "../../helpers/types";
import axios from "axios";
import { ChevronDownIcon } from "@heroicons/react/outline";
import TabFiltersCollections from "components/TabFiltersCollections";
import { Transition } from "@headlessui/react";
import { doGet } from "helpers/api_helper";
import { useLocation } from 'react-router-dom';
import { useParams } from "react-router-dom";
import GeomBackground from "components/Ui/BgGeom";
import { InfiniteMovingCards } from "components/Ui/MovingCards";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "components/Ui/Drawer";
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getCollectionsFromBackend } from "helpers/helpers";

export interface PageSearchProps {
  className?: string;
}

const PageDiscover: FC<PageSearchProps> = ({ className = "" }) => {
  const [loadedData, setLoadedData] = React.useState<Collection[]>([]);
  const [tabActive, setTabActive] = React.useState("All Collections");
  const [isOpen, setIsOpen] = React.useState(false);
  const [curatedCollection, setCuratedCollection] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState("");
  const [numAssetsDisplayed, setNumAssetsDisplayed] = React.useState<number>(9);
  const [numAssetsLoaded, setNumAssetsLoaded] = React.useState<number>(0);
  const [sortOrderState, setSortOrderState] = React.useState<string>('alpha');
  const [rangePrices, setRangePrices] = React.useState<[number, number]>([0, Infinity]);

  const location = useLocation();
  const { ref, inView } = useInView();

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ['collections', tabActive, curatedCollection, searchValue, sortOrderState, rangePrices],
    queryFn: async ({ pageParam = 1 }) => {
      const response = await getCollectionsFromBackend(
        pageParam,
        9,
        sortOrderState,
        rangePrices,
        searchValue,
        tabActive !== "All Collections" ? [tabActive] : undefined,
        curatedCollection
      );
      return response;
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length > 0 ? allPages.length + 1 : undefined;
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const handleCuratedCollectionChange = (value: boolean) => {
    setCuratedCollection(value);
    setNumAssetsDisplayed(9);
    setNumAssetsLoaded(0);
    setLoadedData([]);
  };

  const handleTabChange = (tab: string) => {
    if (tabActive !== tab) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('tag', tab);
      const newUrl = `${location.pathname}?${searchParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);

      setTabActive(tab);
      setNumAssetsDisplayed(9);
      setLoadedData([]);
      setNumAssetsLoaded(0);
      console.log(curatedCollection)

    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tagFromUrl = searchParams.get('tag');
    if (tagFromUrl) {
      setTabActive(tagFromUrl);
    }
  }, [location.search]);

  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      setSearchValue(value);
      setNumAssetsDisplayed(9);
      setLoadedData([]);
      setNumAssetsLoaded(0);
    }, 300);
  };

  const handleSearchSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSortOrderChange = React.useCallback((newSortOrderState: string) => {
    setSortOrderState(newSortOrderState);
  }, []);

  const handleShowMore = () => {
    setNumAssetsLoaded(numAssetsLoaded + numAssetsDisplayed);
    setNumAssetsDisplayed((prevState) => prevState + 6);
  };

  const handleRangePricesChange = (newRangePrices: [number, number]) => {
    setRangePrices(newRangePrices);
    setNumAssetsDisplayed(9);
    setLoadedData([]);
    setNumAssetsLoaded(0);
  };

  return (
    <div className={`nc-PageSearch  ${className}`} data-nc-id="PageSearch">
      <Helmet>
        <title>Cardano Art - Search Collections</title>
      </Helmet>
      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      >
        <GeomBackground type="AwokenBackground" />
      </div>
      <div className="container">
        <header className="max-w-2xl mx-auto -mt-10 flex flex-col lg:-mt-7">
          <form className="relative w-full ">
            <label
              htmlFor="search-input"
              className="text-neutral-500 dark:text-neutral-300"
            >
              <span className="sr-only">Search all icons</span>
              <Input
                className="shadow-lg border-0 dark:border"
                id="search-input"
                type="search"
                placeholder="Search by collection name"
                sizeClass="pl-14 py-5 pr-5 md:pl-16"
                rounded="rounded-full"
                onChange={handleSearchChange}
              />
              <ButtonCircle
                className="absolute right-2.5 top-1/2 transform -translate-y-1/2"
                size=" w-11 h-11"
                onClick={handleSearchSubmit}
              >
                <i className="las la-arrow-right text-xl"></i>
              </ButtonCircle>
              <span className="absolute left-5 top-1/2 transform -translate-y-1/2 text-2xl md:left-6">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </label>
          </form>
        </header>
      </div>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
        <main>
          {/* FILTER */}
          <div className={`flex flex-col relative ${className}`}>

            <div className="flex flex-col lg:flex-row lg:items-center justify-between space-y-6 lg:space-y-0 lg:space-x-2 ">
              <HeaderFilterSearchPage onTabChange={handleTabChange} activeTab={tabActive} />
              <div className="flex items-center">
                <Drawer>
                  <DrawerTrigger>
                    <ButtonSecondary className="w-auto w-48" sizeClass="py-2.5 " disabled>Upcoming Releases</ButtonSecondary>
                  </DrawerTrigger>
                  <DrawerContent className="bg-white dark:bg-neutral-900 dark:border-neutral-800">
                    <div className=" flex flex-col antialiased bg-white dark:bg-neutral-900  relative overflow-hidden">
                      <h2 className={`text-2xl md:text-3xl font-semibold text-transparent bg-clip-text dark:bg-gradient-to-r bg-black dark:from-primary-500 dark:to-secondary-400 text-center justify-center`}>
                        Upcoming Releases
                      </h2>
                      <InfiniteMovingCards
                        data={loadedData}
                        direction="left"
                        speed="fast"
                        pauseOnHover={true}
                      />
                    </div>
                    <DrawerFooter>
                      <button className="p-[3px] relative">
                        <div className="absolute inset-0 bg-gradient-to-r from-primary-6000 to-primary-800 rounded-lg" />
                        <div className="px-8 py-2 bg-primary-6000 rounded-[6px]  relative group transition duration-200 text-white hover:bg-transparent">
                          View Full Calander
                        </div>
                      </button>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
                <ButtonPrimary
                  className="w-auto !pr-16 ml-2"
                  sizeClass="pl-4 py-2.5 sm:pl-6"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    className={`w-4 h-4 sm:w-6 sm:h-6`}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.87 17.12L18.87 16.12"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className="block truncate ml-2.5">Filters</span>
                  <span className="absolute top-1/2 -translate-y-1/2 right-5">
                    <ChevronDownIcon
                      className={`w-4 h-4 sm:w-5 sm:h-5 ${isOpen ? "rotate-180" : ""}`}
                      aria-hidden="true"
                    />
                  </span>
                </ButtonPrimary>
              </div>
            </div>
            <Transition
              show={isOpen}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-4"></div>
              <TabFiltersCollections
                isCuratedCollection={curatedCollection}
                onCuratedCollectionChange={handleCuratedCollectionChange}
                sortOrderState={sortOrderState}
                onSortOrderChange={handleSortOrderChange}
                rangePrices={rangePrices}
                setRangePrices={handleRangePricesChange}
              />
            </Transition>
          </div>
          {/* LOOP ITEMS */}
          <div className="min-h-screen">
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-8 gap-y-10 mt-8 lg:mt-10">
              {data?.pages.map((page) => (
                <React.Fragment key={page.nextId}>
                  {page.map((asset: Collection) => (
                    <CollectionProject
                      key={asset.id}
                      id={asset.id}
                      name={asset.name}
                      cardpath={asset.cardpath}
                      owner={asset.owner}
                      cardtype={asset.cardtype}
                      curated={asset.curated}
                      avatarpath={asset.avatarpath}
                      tags={asset.tags}
                      description={asset.description}
                      path={asset.path}
                      policy={asset.policy}
                      floorPrice={asset.floorPrice}
                      className="fade-in"
                    />
                  ))}
                </React.Fragment>
              ))}
              {hasNextPage && (
                <div ref={ref}>
                  {isFetchingNextPage
                    ? 'Loading more...'
                    : 'Loading completed'}
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default PageDiscover;
