import React, { useEffect } from "react";
import MyRouter from "routers/index";
import { MeshProvider } from "@meshsdk/react";
import { Helmet } from 'react-helmet';

function App() {
  useEffect(() => {
    // Set prerenderReady to true when the app has finished rendering
    window.prerenderReady = true;
  }, []);

  return (
    <MeshProvider>
      <Helmet>
        <script>{`window.prerenderReady = false;`}</script>
      </Helmet>
      <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
        <MyRouter />
      </div>
    </MeshProvider>
  );
}

export default App;