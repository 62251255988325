import React from "react";
import Awoken from "../../components/Awoken/AwokenHeader";

interface GridProps {
    type?: string;
    heading?: string;
    subtitle?: string;
}

export function GridSmallBackground({ heading, subtitle }: GridProps) {
    return (
        <div className="h-full w-full dark:bg-neutral-800/20 bg-white  dark:bg-grid-small-white/[0.2] bg-grid-small-black/[0.2] relative flex flex-col items-center justify-center py-16">
            <h2 className="text-3xl font-semibold pt-3">
                {heading ? heading : ""}
            </h2>
            <p className="mt-3 text-neutral-800 dark:text-neutral-400 text-xs md:text-sm p-2 ">
                {subtitle ? subtitle : ""}
            </p>
            <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-neutral-800/20 bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
        </div>
    );
}

export function DotBackground() {
    return (
        <div className="h-full w-full dark:bg-neutral-800/20 bg-white dark:bg-dot-white/[0.2] bg-dot-black/[0.2] relative flex items-center justify-center">
            {/* Radial gradient for the container to give a faded look */}
            <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-neutral-800/20 bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
        </div>
    );
}

export function GridBackground() {
    return (
        <div className="h-full w-full dark:bg-neutral-800/20 bg-white  dark:bg-grid-white/[0.2] bg-grid-black/[0.2] relative flex items-center justify-center">
            {/* Radial gradient for the container to give a faded look */}
            <div className="absolute pointer-events-none inset-0 flex items-center justify-center dark:bg-neutral-800/20 bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black)]"></div>
        </div>
    );
}

export function AwokenBackground({ heading, subtitle }: GridProps) {
    return (
        <div className="relative h-full w-full overflow-hidden">
            {/* Awoken canvas */}
            <div className="absolute inset-0">
                <Awoken />
            </div>
            
            {/* Fade overlay */}
            <div className="absolute inset-0 pointer-events-none">
                <div className="absolute inset-y-0 left-0 w-16 sm:w-32 md:w-48 lg:w-64 bg-gradient-to-r from-white dark:from-neutral-900 to-transparent"></div>
                <div className="absolute inset-y-0 right-0 w-16 sm:w-32 md:w-48 lg:w-64 bg-gradient-to-l from-white dark:from-neutral-900 to-transparent"></div>
                <div className="absolute inset-x-0 bottom-0 h-16 sm:h-24 md:h-32 bg-gradient-to-t from-white dark:from-neutral-900 to-transparent"></div>
            </div>
            
            {/* Content */}
            <div className="relative h-full w-full flex flex-col items-center justify-center py-16">
                <div className="text-white">
                    {heading && (
                        <h2 className="text-2xl sm:text-3xl font-semibold p-2 text-center rounded-lg backdrop-blur-md bg-black/30">
                            {heading}
                        </h2>
                    )}
                    {subtitle && (
                        <p className="mt-3 text-xs sm:text-sm p-2 text-center rounded-lg backdrop-blur-md bg-black/30">
                            {subtitle}
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

const GridTypes = {
    GRID: 'grid',
    DOT: 'dot',
    GRID_SMALL: 'grid_small',
    AWOKEN_BACKGROUND: 'AwokenBackground',
};

function GeomBackground({ type, heading, subtitle }: GridProps) {
    const getRandomBackground = () => {
        const backgrounds = [GridTypes.GRID, GridTypes.DOT, GridTypes.GRID_SMALL, GridTypes.AWOKEN_BACKGROUND];
        const randomIndex = Math.floor(Math.random() * backgrounds.length);
        return backgrounds[randomIndex];
    };

    const renderBackground = (type: string) => {
        switch (type) {
            case GridTypes.GRID:
                return <GridBackground />;
            case GridTypes.DOT:
                return <DotBackground />;
            case GridTypes.GRID_SMALL:
                return <GridSmallBackground heading={heading} subtitle={subtitle} />;
            case GridTypes.AWOKEN_BACKGROUND:
                return <AwokenBackground heading={heading} subtitle={subtitle} />;
            default:
                return null;
        }
    };

    const selectedBackground = type || getRandomBackground();
    return renderBackground(selectedBackground);
}

export default GeomBackground;
