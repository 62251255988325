import React, { useEffect, useRef, useState } from 'react';
import { Collection } from '../../helpers/types';
import { cn } from '../../utils/cn';
import { Link } from 'react-router-dom';
import Card from 'containers/DiscoverPage/Card';
import VerifyIcon from 'components/VerifyIcon';
import CollectionOnchain from 'components/CollectionOnchain';
import CollectionTags from 'components/CollectionTags';

export const InfiniteMovingCards = ({
    data,
    direction = "left",
    speed = "fast",
    pauseOnHover = true,
    className,
}: {
    data: Collection[];
    direction?: "left" | "right";
    speed?: "fast" | "normal" | "slow";
    pauseOnHover?: boolean;
    className?: string;
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const scrollerRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        addAnimation();
    }, []);

    const [start, setStart] = useState(false);
    const [repeatedData, setRepeatedData] = useState<Collection[]>([]);

    function addAnimation() {
        if (containerRef.current && scrollerRef.current) {
            const containerWidth = containerRef.current.offsetWidth;

            // Calculate total width of existing cards
            const totalCardsWidth = data.length > 0 ? data.length * 300 /* Assuming fixed width of 300px per card */ : 0;

            // Calculate number of clones needed to fill container width
            const clonesNeeded = Math.ceil(containerWidth / totalCardsWidth * 3);

            // Repeat the data until enough cards are available
            const repeatedData = [];
            for (let i = 0; i < clonesNeeded; i++) {
                repeatedData.push(...data);
            }

            // Set the repeated data
            setRepeatedData(repeatedData);

            getDirection();
            getSpeed();
            setStart(true);
        }
    }

    const getDirection = () => {
        if (containerRef.current) {
            const animationDirection = direction === "left" ? "forwards" : "reverse";
            containerRef.current.style.setProperty("--animation-direction", animationDirection);
        }
    };

    const getSpeed = () => {
        if (containerRef.current) {
            let animationDuration = "20s";
            if (speed === "normal") animationDuration = "40s";
            else if (speed === "slow") animationDuration = "80s";
            containerRef.current.style.setProperty("--animation-duration", animationDuration);
        }
    };

    console.log("show data", data)
    return (
        <div
            ref={containerRef}
            className={cn(
                className,
                "scroller relative z-20 overflow-hidden mask-image:linear-gradient(to_right,transparent,white_20%,white_80%,transparent)",
                pauseOnHover && "hover:[animation-play-state:paused]"
            )}
        >
            <ul
                ref={scrollerRef}
                className={cn(
                    "flex shrink-0 gap-4 py-4 flex-nowrap",
                    start && "animate-scroll ",
                    pauseOnHover && "hover:[animation-play-state:paused]"
                )}
            >
                {repeatedData.map((item, index) => (
                    <div
                        key={index}
                        className="nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] fade-in max-w-[19rem] min-w-[19rem] h-full ">
                        <Link to={`/collection/${item.path}`} className="">
                            <div className="flex flex-col items-start mx-auto relative pb-2">
                                <Card cardpath={item.cardpath} name={item.name} />
                                <div className="flex flex-col justify-center absolute right-3 top-[11.2rem] bg-white dark:bg-neutral-900 dark:text-white rounded p-1 dark:shadow dark:shadow-neutral-900">
                                    <span className="flex items-center text-xs">
                                        <span className="font-light italic mr-1">by </span>
                                        <span className="font-medium text-md">{item.owner}</span>
                                        {item.curated === true ? <VerifyIcon iconClass="w-4 h-4 " /> : " "}
                                    </span>
                                </div>
                                <div className="flex flex-col justify-center absolute left-3 top-2 bg-white dark:bg-neutral-900 dark:text-white rounded p-1 dark:shadow dark:shadow-neutral-900">
                                    <span className="text-xs">
                                        <span className="font-medium text-md">Release Date: 21/05/24</span>
                                    </span>
                                </div>
                                <div className="flex items-center justify-center absolute top-2 right-2 transform -translate-x-1/2 bg-white dark:bg-neutral-900 rounded p-1 shadow dark:shadow dark:shadow-neutral-900">
                                    <CollectionOnchain tags={item.tags} blend={true} size="small" />
                                </div>
                                <p className="dark:text-white text-black mt-4 text-sm font-light px-2 min-h-[2.6rem]">
                                    {item.description && item.description.length > 65 ? `${item.description.substring(0, 65)}..` : item.description}
                                </p>

                                <p className="text-sm border font-light dark:border-white/[0.2] border-black/[0.2] rounded-full mt-4 text-black dark:text-white mx-2 px-2 py-0.5">
                                    Mint Price: 20 ADA
                                </p>
                            </div>
                        </Link>
                    </div>
                ))}
            </ul>
        </div>
    );
};
