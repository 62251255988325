import { useState } from "react";
import { MultiSelect, ComboboxItem, OptionsFilter } from '@mantine/core';

interface SelectTagsProps {
  formLabel?: string;
  onChange?: (selectedValues: string[]) => void;
  value?: string[];
}

const SelectTags = ({ formLabel, onChange, value }: SelectTagsProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>(value || []);

  const handleChange = (newValues: string[]) => {
    setSelectedValues(newValues);
    if (onChange) {
      onChange(newValues);
    }
  };

  const data = [
    { value: "On-Chain", label: "On-Chain" },
    { value: "Generative", label: "Generative" },
    { value: "Interactive", label: "Interactive" },
    { value: "Gaming", label: "Gaming" },
    { value: "Pixel", label: "Pixel" },
    { value: "Evolving", label: "Evolving" },
    { value: "PFP", label: "PFP" },
    
  ];

  // Custom filter function to only show options with values in the predefined set
  const optionsFilter: OptionsFilter = ({ options, search }) => {
    const splittedSearch = search.toLowerCase().trim().split(' ');
    return (options as ComboboxItem[]).filter((option) => {
      const words = option.label.toLowerCase().trim().split(' ');
      return splittedSearch.every((searchWord) => words.some((word) => word.includes(searchWord)));
    });
  };

  return (
    <div className="my-2">
      <div className="text-sm text-black dark:text-white font-medium">{formLabel}</div>

      <MultiSelect
        placeholder="Pick values"
        value={selectedValues}
        onChange={handleChange}
        maxDropdownHeight={160}
        data={data}
        filter={optionsFilter}
        searchable
      />
    </div>
  );
};

export default SelectTags;
