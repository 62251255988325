import React, { useState } from 'react';
import { UserContext } from './UserContext';
import { ColumnDef } from "@tanstack/react-table";
import { User } from "helpers/types";
import { MoreHorizontal, ArrowUpDown, } from "lucide-react"
import { Button } from "../../../components/Ui/Button"
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import {
    HamburgerMenuIcon,
    CheckIcon,
} from '@radix-ui/react-icons';
import { Link } from "react-router-dom";
import { deleteUser, resetUserImages, updateUserRole, updateUserType } from "../helpers";
import { Toast } from 'flowbite-react';
import { FaTelegramPlane } from 'react-icons/fa';

const ActionCell = ({ row }: { row: any }) => {
    const { fetchData } = React.useContext(UserContext);
    const userName = row.original.name;
    const userId = row.original.id;
    const userRole = row.original.role;
    const userArtist = row.original.artist;
    const [isOpen, setIsOpen] = useState(false);
    const [showResetToast, setShowResetToast] = useState(false);


    const handleDelete = async () => {
        try {
            await deleteUser(userId);
            fetchData();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };

    const handleRoleChange = async (newRole: string) => {
        try {
            await updateUserRole(userId, newRole);
            fetchData();
        } catch (error) {
            console.error("Error updating user role:", error);
        }
    };

    const handleTypeChange = async (newType: boolean) => {
        try {
            await updateUserType(userId, newType);
            fetchData();
        } catch (error) {
            console.error("Error updating user type:", error);
        }
    };

    const handleResetImage = async () => {
        try {
            await resetUserImages(userId);
            fetchData();
            setShowResetToast(true);
            setTimeout(() => setShowResetToast(false), 4000);
        } catch (error) {
            console.error("Error resetting user images:", error);
        }
    };

    return (
        <div className="font-medium text-left">
            <DropdownMenu.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
                <DropdownMenu.Trigger asChild>
                    <button
                        className="rounded-full w-[35px] h-[35px] inline-flex items-center justify-center text-black dark:text-white  shadow-blackA4 outline-none hover:bg-violet3 "
                        aria-label="actions"
                    >
                        <HamburgerMenuIcon />
                    </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className="min-w-[220px] bg-white dark:bg-neutral-700 text-black dark:text-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
                        sideOffset={5}
                    >
                        <Link to={`/${userName}`}>
                            <DropdownMenu.Item className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none ">
                                View Profile{' '}
                            </DropdownMenu.Item>
                        </Link>
                        <DropdownMenu.Item className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none ">
                            Edit Balance{' '}
                        </DropdownMenu.Item>
                        <DropdownMenu.Item onClick={handleResetImage} className="group text-[13px] text-red-500 hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none ">
                            Remove Images{' '}
                        </DropdownMenu.Item>
                        <AlertDialog.Root>
                            <AlertDialog.Trigger asChild >
                                <span className="group text-[13px] text-red-500 hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none ">
                                    Delete User
                                </span>
                            </AlertDialog.Trigger>
                            <AlertDialog.Portal>
                                <AlertDialog.Overlay className="bg-blackA6 data-[state=open]:animate-overlayShow fixed inset-0" />
                                <AlertDialog.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[500px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                                    <AlertDialog.Title className="text-mauve12 m-0 text-[17px] font-medium">
                                        Are you absolutely sure?
                                    </AlertDialog.Title>
                                    <AlertDialog.Description className="text-mauve11 mt-4 mb-5 text-[15px] leading-normal">
                                        This action cannot be undone. This will permanently delete the account and remove the
                                        data from our servers.
                                    </AlertDialog.Description>
                                    <div className="flex justify-end gap-[25px]">
                                        <AlertDialog.Cancel asChild>
                                            <button onClick={() => setIsOpen(false)} className="text-black hover:bg-primary-6000 hover:text-white inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none">
                                                Cancel
                                            </button>
                                        </AlertDialog.Cancel>
                                        <AlertDialog.Action asChild>
                                            <button onClick={handleDelete} className="text-red-500 bg-gray-100 hover:bg-primary-6000 hover:text-white inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-medium leading-none outline-none">
                                                Yes, delete account
                                            </button>
                                        </AlertDialog.Action>
                                    </div>
                                </AlertDialog.Content>
                            </AlertDialog.Portal>
                        </AlertDialog.Root>
                        <DropdownMenu.Separator className="h-[1px] bg-slate-200 m-[5px]" />
                        <DropdownMenu.Label className="pl-[25px] text-xs leading-[25px] text-gray-400">
                            Select User Type
                        </DropdownMenu.Label>
                        <DropdownMenu.CheckboxItem
                            className="group hover:bg-primary-6000 hover:text-white text-[13px] leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none "
                            checked={userArtist === false}
                            onCheckedChange={() => handleTypeChange(false)}
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            User{' '}
                        </DropdownMenu.CheckboxItem>
                        <DropdownMenu.CheckboxItem
                            className=" hover:bg-primary-6000 hover:text-white text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none "
                            checked={userArtist === true}
                            onCheckedChange={() => handleTypeChange(true)}
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            Artist
                        </DropdownMenu.CheckboxItem>
                        <DropdownMenu.Separator className="h-[1px] bg-slate-200 m-[5px]" />
                        <DropdownMenu.Label className="pl-[25px] text-xs leading-[25px] text-gray-400">
                            Select User Permissions
                        </DropdownMenu.Label>
                        <DropdownMenu.CheckboxItem
                            className="group hover:bg-primary-6000 hover:text-white text-[13px] leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none"
                            checked={userRole === 'USER'}
                            onCheckedChange={() => handleRoleChange('USER')}
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            User
                        </DropdownMenu.CheckboxItem>
                        <DropdownMenu.CheckboxItem
                            className="hover:bg-primary-6000 hover:text-white text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none"
                            checked={userRole === 'ADMIN'}
                            onCheckedChange={() => handleRoleChange('ADMIN')}
                        >
                            <DropdownMenu.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
                                <CheckIcon />
                            </DropdownMenu.ItemIndicator>
                            Admin
                        </DropdownMenu.CheckboxItem>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
            {showResetToast && (
    <div className="fixed top-4 right-4 z-50 flex items-center bg-gray-800 text-white rounded p-2">
        <FaTelegramPlane className="h-5 w-5 text-cyan-600 dark:text-cyan-500" />
        <div className="pl-2 p-4 text-sm font-normal">Images reset successfully.</div>
    </div>
)}
        </div>

    );
}

export default ActionCell;