import React, { FC } from "react";

const BannerBreak: FC = ({}) => {
  return (
    <div
      className={`nc-HeadBackgroundCommon h-24 2xl:h-28 top-0 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
      data-nc-id="HeadBackgroundCommon"
    />
  );
};

export default BannerBreak;
