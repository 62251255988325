import React from "react";
import { Badge } from "flowbite-react";
import { LinkIcon } from "@heroicons/react/outline";
import colors from 'tailwindcss/colors';
import { ClassNames } from "@emotion/react";
import { Link } from "react-router-dom";

interface Props {
  tags?: string[];
  className?: string;
  clickable?: boolean;
}

const CollectionTags: React.FC<Props> = ({ tags = [], className, clickable }) => {
  if (!tags || tags.length === 0) {
    return null;
  }

  const tagColor = colors.neutral[700];

  const getColorByTag = (tag: string) => {
    switch (tag) {
      case "Generative":
        return "zinc-800";
      case "Interactive":
        return "zinc-800";
      case "Game":
        return "zinc-800";
      case "Pixel":
        return "zinc-800";
      case "JPEG":
        return "zinc-800";
      default:
        return "zinc-800";
    }
  };

  return (
    <>
      {tags.map((tag, index) => (
        <React.Fragment key={index}>
          {tag !== "On-Chain" && (
            <>
              {clickable ? (
                <span className={index === tags.length - 1 ? "mr-0 mt-2" : "mr-1 mt-2"}>
                  <Link to={`/discover?tag=${tag}`}>
                    <span className={`inline-flex items-center gap-1.5 py-1 px-2 rounded-lg text-xs font-medium bg-custom-tag text-white dark:text-black ${className}`}>{tag}</span>
                  </Link>
                </span>
              ) : (
                <span className={index === tags.length - 1 ? "mr-0 mt-2" : "mr-1 mt-2"}>
                  <span className={`inline-flex items-center gap-1.5 py-1 px-2 rounded-lg text-xs font-medium bg-custom-tag text-white dark:text-black ${className}`}>{tag}</span>
                </span>
              )}
            </>
          )}
        </React.Fragment>
      ))}
    </>
  );
};

export default CollectionTags;
