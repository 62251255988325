import { CollectionContext } from './CollectionContext';
import { columns } from "./Columns";
import { Collection, User } from "helpers/types";
import { DataTable } from "./Table";
import React, { useState, useEffect } from "react";
import { doGet } from "helpers/api_helper";
import { fetchUsers } from 'helpers/helpers';
import NavMenu from '../NavMenu';
import GeomBackground from 'components/Ui/BgGeom';
import { Helmet } from "react-helmet";


function AdminCollections() {
    const [data, setData] = useState<Collection[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        fetchData();
        fetchTheUsers();
    }, []);

    const fetchData = async () => {
        try {
            const fetchedData = await doGet<Collection[]>("/api/collection/search/all");
            if (fetchedData !== null) {
                setData(fetchedData.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const fetchTheUsers = async () => {
        const users = await fetchUsers({ name: true, artist: true });
        if (users) {
            setUsers(users);
        }
    };

    const heading = `Collection Managment`
    const subtitle = `Edit collections and their details`

    return (
        <><Helmet>
            <title>Cardano Art - Admin Panel</title>
        </Helmet><div
            className={`nc-HeadBackgroundCommon h-24 2xl:h-28 mt-12 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
            data-nc-id="HeadBackgroundCommon"
        >
                <NavMenu />
                <GeomBackground type="grid_small" heading={heading} subtitle={subtitle} />
            </div>
            <CollectionContext.Provider value={{ data, fetchData, users, fetchUsers }}>
                <div className="container mx-auto py-20">
                    <DataTable columns={columns} data={data} />
                </div>
            </CollectionContext.Provider></>
    );
}

export default AdminCollections;
