import { Helmet } from "react-helmet";
import NavMenu from "./NavMenu";
import GeomBackground from "components/Ui/BgGeom";

const PageAdmin = () => {

    const heading = `Admin Panel`
    const subtitle = `Select an option from the menu to get started.`
    return (
        <>
            <Helmet>
                <title>Cardano Art - Admin Panel</title>
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon h-24 2xl:h-28 mt-12 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
                data-nc-id="HeadBackgroundCommon"
            >
                <NavMenu />
                <GeomBackground type="grid_small" heading={heading} subtitle={subtitle} />
            </div>
            <div className="w-full min-h-screen">
                <div className="container max-w-8xl">
                    <div className="flex justify-center items-center h-screen">
                        <div className="max-w-screen-lg w-full">
                            <div className="aspect-w-16 aspect-h-9">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default PageAdmin;