import React, { useEffect, useRef } from "react";
import { createNoise3D } from "simplex-noise";

const MOBILE_THRESHOLD = 768;

export default function Awoken() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;
    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const resizeCanvas = () => {
      const { width, height } = container.getBoundingClientRect();
      const isMobile = window.innerWidth <= MOBILE_THRESHOLD;
      const scale = isMobile ? 0.5 : 1;
      canvas.width = Math.floor(width * scale);
      canvas.height = Math.floor(height * scale);
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;
    };

    resizeCanvas();
    window.addEventListener("resize", resizeCanvas);

    const noise3D = createNoise3D();
    let t = 0;
    const dna = {
      dna1: 233,
      dna2: 269,
      dna3: 571,
      dna4: 771,
      dna5: 0.6,
      dna6: 49,
      dna7: 0.23,
      dna8: 0.83,
      dna9: 333,
      dna10: 775,
      dna11: 218,
      dna12: 39,
      dna13: 249,
    };

    workerRef.current = new Worker(
      URL.createObjectURL(
        new Blob(
          [
            `
        self.onmessage = function(e) {
          const { width, height, noiseValues, dna } = e.data;
          const buffer = new Uint8ClampedArray(width * height * 4);
          for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
              const index = x + y * width;
              const r = noiseValues[index * 2];
              const g = noiseValues[index * 2 + 1];
              const data1 = (r || g) * dna.dna11;
              const data3 = dna.dna13;
              const bufferIndex = index * 4;
              buffer[bufferIndex + 0] = data1;
              buffer[bufferIndex + 1] = r * g * dna.dna12;
              buffer[bufferIndex + 2] = data3;
              buffer[bufferIndex + 3] = 255;
            }
          }
          self.postMessage({ buffer: buffer.buffer }, [buffer.buffer]);
        };
      `,
          ],
          { type: "application/javascript" }
        )
      )
    );

    let lastTime = 0;
    const isMobile = window.innerWidth <= MOBILE_THRESHOLD;
    const targetFPS = isMobile ? 18 : 30;
    const frameInterval = 1000 / targetFPS;

    const animate = (currentTime: number) => {
      if (currentTime - lastTime < frameInterval) {
        requestAnimationFrame(animate);
        return;
      }
      lastTime = currentTime;

      const { width, height } = canvas;
      const noiseValues = new Float32Array(width * height * 2);
      for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
          const index = (x + y * width) * 2;
          noiseValues[index] =
            noise3D(x / dna.dna1, y / dna.dna2, t / dna.dna9) *
              dna.dna5 +
            dna.dna7;
          noiseValues[index + 1] =
            noise3D(x / dna.dna3, y / dna.dna4, t / dna.dna10) *
              dna.dna6 +
            dna.dna8;
        }
      }

      workerRef.current?.postMessage(
        { width, height, noiseValues, dna },
        [noiseValues.buffer]
      );
      t++;
      requestAnimationFrame(animate);
    };

    workerRef.current.onmessage = function (e) {
      const { width, height } = canvas;
      const imageData = new ImageData(
        new Uint8ClampedArray(e.data.buffer),
        width,
        height
      );
      ctx.putImageData(imageData, 0, 0);
    };

    requestAnimationFrame(animate);

    return () => {
      window.removeEventListener("resize", resizeCanvas);
      workerRef.current?.terminate();
    };
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: "100%",
          height: "100%",
          display: "block",
        }}
      />
    </div>
  );
}
