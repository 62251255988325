import React, { FC, useState } from "react";

export interface SocialType {
  name: string;
  icon: string;
  href: string;
}

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block w-6 h-6",
  socials = [],
}) => {
  const [copiedDiscord, setCopiedDiscord] = useState(false);

  const handleSocialClick = (item: SocialType) => {
    if (item.name === "Twitter") {
      window.open(`https://x.com/${item.href}`, "_blank");
    } else if (item.name === "Discord") {
      navigator.clipboard.writeText(item.href).then(() => {
        setCopiedDiscord(true);
        setTimeout(() => setCopiedDiscord(false), 2000);
      });
    }
  };

  return (
    <nav
      className={`nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
      data-nc-id="SocialsList"
    >
      {socials.map((item, i) => (
        <button
          key={i}
          className={`${itemClass} relative`}
          onClick={() => handleSocialClick(item)}
          title={item.name}
        >
          <img src={item.icon} alt={item.name} />
          {item.name === "Discord" && copiedDiscord && (
            <span className="absolute -top-8 left-1/2 transform -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded">
              Copied!
            </span>
          )}
        </button>
      ))}
    </nav>
  );
};

export default SocialsList;