import axios, { AxiosRequestConfig } from "axios"


const baseUrl = process.env.ENV == "development" ? process.env.SERVER_BASE_URL : ""

const addAuthTokenToHeaders = (config: AxiosRequestConfig) => {
    const authUser = localStorage.getItem('authUser');
    const token = authUser ? JSON.parse(authUser).token : null;
    if (!token) {
        throw new Error("user token not found");
    }
    if (!!!config.headers) {
        config.headers = {}
    }
    config.headers["Authorization"] = "Bearer " + token
}

export const doGet = async <T = any>(url: string, config: AxiosRequestConfig = {}, auth = false) => {
    if (auth) {
        addAuthTokenToHeaders(config)
    }
    return axios.get<T>(baseUrl + url, config)
}

export const doExternalGet = async <T = any>(url: string) => {
    return axios.get<T>(url)
}

export const doPost = async <T = any>(url: string, data: any, config: AxiosRequestConfig, auth = false) => {
    if (auth) {
        addAuthTokenToHeaders(config)
    }
    return axios.post<T>(baseUrl + url, data, config)
}

export const doPut = async <T = any>(url: string, data: any, config: AxiosRequestConfig, auth = false) => {
    if (auth) {
        addAuthTokenToHeaders(config)
    }
    return axios.put<T>(baseUrl + url, data, config)
}

export const doDelete = async <T = any>(url: string, config: AxiosRequestConfig, auth = false) => {
    if (auth) {
        addAuthTokenToHeaders(config)
    }
    return axios.delete<T>(baseUrl + url, config)
}