import { Fragment } from "react";
import { XIcon } from "@heroicons/react/outline";
import classNames from "classnames";

interface SideMenuProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  children: React.ReactNode;
}

const SideMenu: React.FC<SideMenuProps> = ({
  isOpen,
  onClose,
  className,
  children,
}) => {
  const overlayClasses = classNames("fixed inset-0 z-30 bg-black opacity-30 ", {
    hidden: !isOpen,
  });

  const menuClasses = classNames(
    "fixed inset-y-0 left-0 z-40 w-72 bg-white shadow-lg overflow-y-auto transition duration-300 transform dark:bg-neutral-800 ",
    {
      "-translate-x-full": !isOpen,
    },
    className
  );

  const scrollbarStyles = `
    /* Style your custom scrollbar here */
    ::-webkit-scrollbar {
      width: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: grey; /* Change this to your desired scrollbar color */
      border-radius: 4px;
    }
  `;

  return (
    <Fragment>
      <style>{scrollbarStyles}</style>
      <div className={overlayClasses} onClick={onClose} />
      <div className={menuClasses}>
        <div className="flex items-center justify-between p-2 bg-primary-6000">
          <span className="flex-grow text-center pl-5">Filter Collection Metadata</span>
          <button onClick={onClose}>
            <XIcon className="w-6 h-6 text-white hover:text-gray-800 dark:hover:text-gray-800" />
          </button>
        </div>
        <div className="p-4">{children}</div>
      </div>
    </Fragment>
  );
};

export default SideMenu;
