import * as React from "react"
import { Navbar } from "flowbite-react";
import { Link } from "react-router-dom";

const NavMenu = () => {

  return (
    <Navbar fluid rounded className="bg-gray-100">
      <Navbar.Brand  >
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">Admin Menu</span>
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Navbar.Link href="/admin">
          <Link to="/admin">
            Home
          </Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/admin/collections">
            Manage Collections
          </Link>
        </Navbar.Link>
        <Navbar.Link>
          <Link to="/admin/collections/create">
            Create Collection
          </Link>
        </Navbar.Link>
        <Navbar.Link >
          <Link to="/admin/users">
            Manage Users
          </Link>
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default NavMenu

