import { doDelete, doPut, doGet } from "helpers/api_helper"

export const updateCollectionOwner = async (
    userId: string,
    userName: string,
    collectionId: string
): Promise<void> => {
    try {
        const requestData = { userId, userName, collectionId };
        await doPut(`/api/admin/collection/update-owner`, requestData, {}, true);
    } catch (error) {
        console.error('Error updating collection owner:', error);
        throw error;
    }
};

export const updateUserType = async (userId: string, isArtist: boolean): Promise<void> => {
    try {
        const requestData = { isArtist };
        await doPut(`/api/admin/user/${userId}/type`, requestData, {}, true);
    } catch (error) {
        console.error('Error updating user type:', error);
        throw error;
    }
};

export const updateUserRole = async (userId: string, newRole: string): Promise<void> => {
    try {
        const requestData = { role: newRole };
        await doPut(`/api/admin/user/${userId}/role`, requestData, {}, true);
    } catch (error) {
        console.error('Error updating user role:', error);
        throw error;
    }
};

export const resetUserImages = async (userId: string): Promise<void> => {
    try {
        const requestData = { avatarPath: null, bannerPath: null };
        await doPut(`/api/admin/user/${userId}/reset-images`, requestData, {}, true);
    } catch (error) {
        console.error('Error resetting user images:', error);
        throw error;
    }
};

export const deleteUser = async (userId: string) => {
    await doDelete(`/api/admin/user/${userId}`, {}, true)
}

export const deleteCollection = async (policy: string) => {
    await doDelete(`/api/collection/${policy}`, {}, true)
}

