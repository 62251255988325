import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";

interface Props {
    onFileSelect: (file: File, previewType: "image" | "video" | null ) => void;
    name: string;
    key: string;
  }

  const MediaUpload: React.FC<Props> = ({ onFileSelect, name, key }) => {
    const [preview, setPreview] = useState<string | null>(null);
    const [previewType, setPreviewType] = useState<"image" | "video" | null>(null);
  
    const onDrop = useCallback(
      (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
  
        const reader = new FileReader();
        reader.onload = () => {
          setPreview(reader.result as string);
        };
        reader.readAsDataURL(file);
  
        if (file.type.startsWith("image/")) {
          setPreviewType("image");
        } else if (file.type.startsWith("video/")) {
          setPreviewType("video");
        } else {
          setPreviewType(null);
        }
  
        onFileSelect(file, previewType);
      },
      [onFileSelect]
    );
  
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  
    React.useEffect(() => {
      setPreview(null);
      setPreviewType(null);
    }, [key]);

  return (
    <div className="max-w-xl" {...getRootProps()}>
      <label
        className="flex justify-center w-full h-32 px-4 transition bg-white dark:bg-neutral-800 border-2 border-gray-300 dark:border-slate-600 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none"
        htmlFor="file-input"
      >
        <span className="flex items-center space-x-2 ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6 text-gray-600 dark:text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
            />
          </svg>
          {isDragActive ? (
            <span className="font-medium text-gray-600 dark:text-white">
              Drop your image here
            </span>
          ) : (
            <span className="font-medium text-gray-600 dark:text-white">
              Drop files to upload, or{" "}
              <span className=" text-primary-6000 underline">browse</span>
            </span>
          )}
        </span>
      </label>
      <input {...getInputProps({ name })} style={{ display: "none" }} />
    {previewType === "image" && preview ? (
      <div className="my-4">
        <img
          src={preview}
          alt="Preview"
          className="object-contain object-center h-48 w-full rounded-lg shadow-md border-2 border-grey-500"
        />
      </div>
    ) : null}
    {previewType === "video" && preview ? (
      <div className="my-4">
        <video
          src={preview}
          className="object-contain object-center h-48 w-full rounded-lg shadow-md border-2 border-grey-500"
          controls
        />
      </div>
    ) : null}
  </div>
);
};

export default MediaUpload;
