import React, { FC, ImgHTMLAttributes, useRef, useState, useEffect } from "react";
import checkInViewIntersectionObserver from "utils/isInViewPortIntersectionObserver";
import PlaceIcon from "./PlaceIcon";
import { doGet } from "helpers/api_helper";
import { baseIpfsUrl } from "helpers/helpers";

export interface NcImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
  defaultImage?: string;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = "",
  alt = "nc-imgs",
  src = "",
  className = "object-cover w-full h-full",
  defaultImage = "",
  ...args
}) => {
  const _containerRef = useRef(null);
  const [__src, set__src] = useState<string | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(false);

  const isBase64 = src ? src.startsWith("data:image") : false;
  const isVideo = !!src && (src.endsWith(".mp4") || src.endsWith(".webm") || src.endsWith(".ogg"));

  const fetchSignedUrl = async (sourceKey: string) => {
    try {
      const response = await doGet<{ signedUrl: string }>(`/api/media/cdn/${sourceKey}`);
      return response.data.signedUrl;
    } catch (error) {
      console.error("Error fetching signed URL:", sourceKey);
      return null;
    }
  };

  const loadImageWithTimeout = (url: string, timeoutMs: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      let timedOut = false;

      const timeout = setTimeout(() => {
        timedOut = true;
        img.src = '';
        reject(new Error('Image load timed out'));
      }, timeoutMs);

      img.onload = () => {
        if (!timedOut) {
          clearTimeout(timeout);
          resolve(url);
        }
      };

      img.onerror = () => {
        if (!timedOut) {
          clearTimeout(timeout);
          reject(new Error('Image load failed'));
        }
      };

      img.src = url;
    });
  };

  const processSrc = async () => {
    if (src) {
      let imageUrl: string | null = src;

      try {
        if (src.startsWith("ipfs://")) {
          const ipfsUrl = baseIpfsUrl + (src as string).substring(7);
          imageUrl = await loadImageWithTimeout(ipfsUrl, 5000); // 5 seconds timeout
        } else if (src.startsWith("/static") || src.startsWith("/images") || src.startsWith("https://ipfs.blockfrost") || isBase64) {
          imageUrl = src;
        } else {
          const signedUrl = await fetchSignedUrl(src);
          if (signedUrl) {
            imageUrl = await loadImageWithTimeout(signedUrl, 5000); // 5 seconds timeout
          } else {
            throw new Error('Failed to fetch signed URL');
          }
        }

        set__src(imageUrl);
      } catch (error) {
        console.error("Error loading image:", error);
        _handleImageError();
      }
    } else {
      set__src(defaultImage);
    }
  };


  const _handleImageError = () => {
    setImageError(true);
    setShowPlaceholder(true); // Show placeholder when image fails to load
  };

  const renderLoadingPlaceholder = () => {
    return showPlaceholder && (
      <div
        className={`${className} animate-shimmer flex items-center justify-center`}
      >
      </div>
    );
  };

  const _imageOnViewPort = async () => {
    if (!src) {
      setImageLoaded(true);
      return true;
    }
  
    try {
      await processSrc();
    } catch (error) {
      console.error("Error processing image:", error);
      _handleImageError();
    }
  
    return true;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPlaceholder(true); // If image loading takes longer than 0.4 seconds, show placeholder
    }, 200);

    return () => clearTimeout(timeout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    _imageOnViewPort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src]);

  return (
    <div className={`nc-NcImage ${containerClassName}`} data-nc-id="NcImage" ref={_containerRef}>
      {isVideo && __src ? (
        <video src={__src} className={className} muted autoPlay></video>
      ) : (
        __src ? (
          <img
            src={__src}
            className={`fade-in  ${className}`}
            loading="lazy"
            onLoad={() => {
              setImageLoaded(true);
              setShowPlaceholder(false);
            }}
            onError={() => _handleImageError()}
            {...args}
          />
        ) : (
          renderLoadingPlaceholder()
        )
      )}
    </div>
  );
};

export default NcImage;
