import React from 'react';

const CardNFTSkeleton = () => {
  return (
    <div className="nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] fade-in">
      <div className="relative flex-shrink-0">
        <div className="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0 aspect-w-12 aspect-h-12 animate-pulse bg-neutral-200 dark:bg-neutral-700"></div>
      </div>
      <div className="p-4 py-5 space-y-3">
        <div className="h-4 bg-neutral-200 dark:bg-neutral-700 rounded-full w-3/4 animate-pulse"></div>
        <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div>
        <div className="flex justify-between items-end">
          <div className="h-4 bg-neutral-200 dark:bg-neutral-700 rounded-full w-1/3 animate-pulse"></div>
          <div className="h-4 w-4 bg-neutral-200 dark:bg-neutral-700 rounded-full animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default CardNFTSkeleton;