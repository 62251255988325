import { MultiSelect } from "@mantine/core";
import { Filter } from "containers/Admin/PageCollectionAdminWip";

interface SelectTraitsProps {
  formLabel?: string;
  onChange?: (selectedValues: string[]) => void;
  filter: Filter;
  selectedValues: string[];
}

const SelectTrait = ({ formLabel, onChange, filter, selectedValues }: SelectTraitsProps) => {
  const handleChange = (selectedValues: string[]) => {
    if (onChange && JSON.stringify(selectedValues) !== JSON.stringify(filter.stringFilter?.selection)) {
      onChange(selectedValues);
    }
  };

  const data = filter.stringFilter?.options.map(o => {
    return { value: o, label: o };
  });

  return (
    <div className="my-2">
      <div className="text-sm text-dark dark:text-white font-medium text-center pb-2">{formLabel}</div>
      <MultiSelect
        data={data || []}
        value={selectedValues}
        onChange={handleChange}
        maxDropdownHeight={160}
      />
    </div>
  );
};

export default SelectTrait;