import { NavItemType } from "shared/Navigation/NavigationItem";
import ncNanoId from "utils/ncNanoId";

const browsePageChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/discover",
    name: "Collections",
  }
  /*
  {
    id: ncNanoId(),
    href: "#",
    name: "Artists",
  },*/

];

const creatorsChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/launchpad",
    name: "Launchpad",
  },
  {
    id: ncNanoId(),
    href: "/claimcollection",
    name: "Claim Collection",
  },

];

const aboutChildMenus: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/team",
    name: "Team",
  },
  {
    id: ncNanoId(),
    href: "/support",
    name: "Support",
  },

];

export const NAVIGATION_MENU_2: NavItemType[] = [
  {
    id: ncNanoId(),
    href: "/",
    name: "Discover",
    type: "dropdown",
    children: browsePageChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "Creators",
    type: "dropdown",
    children: creatorsChildMenus,
  },
  {
    id: ncNanoId(),
    href: "/",
    name: "About",
    type: "dropdown",
    children: aboutChildMenus,
  },
];