import React, { FC } from "react";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";
import { ChevronDownIcon } from "@heroicons/react/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import TabFiltersCollections from "components/TabFiltersCollections";
import { Transition } from "@headlessui/react";

export interface HeaderFilterSearchPageProps {
  className?: string;
  onTabChange: (tab: string) => void;
  activeTab: string;
}

const HeaderFilterSearchPage: FC<HeaderFilterSearchPageProps> = ({
  className = "mb-12",
  onTabChange,
  activeTab,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [tabActive, setTabActive] = React.useState(activeTab);

  React.useEffect(() => {
    setTabActive(activeTab);
  }, [activeTab]);

  function handleTabClick(item: string) {
    setTabActive(item);
    onTabChange(item);
  }

  return (
    <Nav
      className="sm:space-x-2"
      containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar w-full lg:w-[46.6rem] "
    >
      {["All Collections", "Generative", "Interactive", "Pixel", "Gaming", "Evolving", "PFP"].map(
        (item, index) => (
          <NavItem
            key={index}
            isActive={tabActive === item}
            onClick={() => handleTabClick(item)}
          >
            {item}
          </NavItem>
        )
      )}
    </Nav>
  );
};

export default HeaderFilterSearchPage;
