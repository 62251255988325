import React from "react";
import axios from "axios";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import CustomFooter from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/Users/Profile";
import SiteHeader from "containers/SiteHeader";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import PageCollection from "containers/PageCollection/PageCollection";
import PageDiscover from "containers/DiscoverPage/PageDiscover";
import PageConnectWallet from "containers/PageConnectWallet";
import LoadingSpinner from "components/LoadingSpinner";
import PageCollectionEdit from "containers/PageCollection/PageCollectionEdit";
import PageCollectionCreate from "containers/Admin/PageCollectionCreate";
import { doGet } from "helpers/api_helper";
import PageCollectionAdminWip from "containers/Admin/PageCollectionAdminWip";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { selectUser, setLoginData } from "app/auth/login/reducer";
import PageUserSettings from "containers/Users/PageUserSettings";
import ProfilePage from "containers/Users/Profile";
import { socket } from "helpers/websocket";
import AdminUsers from "containers/Admin/Users/Index";
import AdminCollections from "containers/Admin/Collections/Index";
import PageAdmin from "containers/Admin/PageAdmin";
import DiscoverArtists from "containers/DiscoverArtists/Index";
import Launchpad from "containers/Launchpad";
import ClaimCollection from "containers/ClaimCollection";
import Support from "containers/Support";
import Team from "containers/Team";
import Terms from "containers/Terms";
import Privacy from "containers/Privacy";


export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/#", component: PageHome },
  { path: "/home-header-2", component: PageHome },
  { path: "/asset", component: NftDetailPage },
  { path: "/discover", component: PageDiscover },
  { path: "/connect-wallet", component: PageConnectWallet },
];

const MyRoutes = () => {
  const user = useAppSelector(selectUser)
  const [isConnected, setIsConnected] = React.useState(socket.connected);

  const dispatch = useAppDispatch()
  React.useEffect(() => {
    const u = localStorage.getItem("authUser")
    if (u) {
      dispatch(setLoginData(JSON.parse(u)))
    }
  }, []);

  React.useEffect(() => {
    // socket.connect()
    if (!!!user) {
      console.log("no user connected");
      return
    }

    function onConnect() {
      console.log("onconnect");
      setIsConnected(true);

      socket.emit("registeruser", {userId: user?.id})
    }

    function onDisconnect() {
      console.log("ondisconnect");
      setIsConnected(false);
    }

    function onFooEvent(value: any) {
      console.log("RECEIVE", value);
      
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('TEST', onFooEvent);

    socket.connect()

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      // socket.off('foo', onFooEvent);
    };
  }, [!!user]);

  return (
    <BrowserRouter basename={process.env.NODE_ENV === "production" ? "" : ""}>
      <ScrollToTop />
      <SiteHeader />
      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route path="/collection/:path" element={<PageCollection />} />
        <Route
          path="/collection/:path/admin"
          element={<PageCollectionAdminWip />}
        />
        <Route
          path="/collection/:path/edit"
          element={<PageCollectionEdit />}
        />
        <Route
          path="/:path/:assetId"
          element={<NftDetailPage />}
        />
        <Route
          path="/:name"
          element={<ProfilePage/>}
        />
         <Route
          path="/@:name"
          element={<ProfilePage />}
        />
        <Route path="/discover/artists" element={<DiscoverArtists />} />
        <Route path="/launchpad" element={<Launchpad />} />
        <Route path="/claimcollection" element={<ClaimCollection/>} />
        <Route path="/support" element={<Support/>} />
        <Route path="/team" element={<Team/>} />
        <Route path="/settings" element={<PageUserSettings />} />
        <Route path="/admin" element={<PageAdmin />} />
        <Route path="/admin/users" element={<AdminUsers />} />
        <Route path="/admin/collections" element={<AdminCollections />} />
        <Route path="/admin/collections/create" element={<PageCollectionCreate />} />

        <Route path="/terms" element={<Terms/>}/>
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />

      </Routes>
      <CustomFooter />
    </BrowserRouter>
  );
};



export default MyRoutes;
