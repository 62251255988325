import React from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';

const Hero: React.FC = () => {
    return (
        <div className="relative mx-auto px-4 sm:px-6">
            <div className="grid md:grid-cols-2 gap-4 md:gap-8 xl:gap-20 md:items-center">
                <aside className="relative w-full max-w-xl lg:max-w-md xl:max-w-xl -ml-4 sm:-ml-8 mt-24 lg:mt-0 lg:mr-24 xl:mr-28">
                    {window.innerWidth >= 700 && (
                        <div className="absolute inset-0 rounded-xl transform origin-bottom rotate-6 bg-primary-6000 shadow-xl shadow-slate-500 dark:shadow-primary-6000"></div>
                    )}
                    <div className="rounded-xl bg-blue-50 bg-opacity-60 bg-blur-10 relative">
                        <div className="p-3 rounded-xl shadow-sm">
                            <div className="aspect-w-1200 aspect-h-630">
                                <img
                                    src="https://pbs.twimg.com/media/FiCYHgPXEAQSUwX?format=jpg&name=large"
                                    alt=""
                                    className="rounded-lg object-cover "
                                />
                            </div>
                        </div>
                    </div>
                </aside>
                <div>
                    <h1 className="block text-3xl font-bold text-gray-800 sm:text-4xl lg:text-6xl lg:leading-tight dark:text-white">
                        Featured Artist <span className="text-primary-6000">Egon FX</span>
                    </h1>
                    <p className="mt-3 text-lg">
                    Our team holds immense appreciation for the artist Egon FX due to the profound inspiration his work has instilled within us. His captivating creations have served as a wellspring of creativity, fuelling our development of aw0k3n algorithms. Through his work we were propelled to explore new realms of innovation, pushing the boundaries of what we thought was possible. Egon FX's influence is not only evident in our work but also in the very essence of our team's creative spirit, driving us to continually strive for excellence and innovation.
                    </p>
                    <div className="mt-7 grid gap-3 w-full sm:inline-flex">
                        <Link to="/collection/stellarhoodouterworlds">
                            <ButtonPrimary className="flex-1 w-full">
                                View Stellar Hood Collection
                            </ButtonPrimary>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
