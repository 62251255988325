import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";
import { MantineProvider, createTheme } from '@mantine/core';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "app/store";
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

// declare the global prerenderReady property
declare global {
  interface Window { prerenderReady: boolean; }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 minutes stale time
    },
  },
})
const theme = createTheme({
  /** Put your mantine theme override here */
});

// Set prerenderReady to false initially
window.prerenderReady = false;

root.render(
  <Provider store={store}>
     <MantineProvider theme={theme}>
     <QueryClientProvider client={queryClient}>
      <App />
      </QueryClientProvider>
    </MantineProvider>
  </Provider>
);

reportWebVitals();