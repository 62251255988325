import React, { FC, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Heading from "components/Heading/Heading";
import CardNFT from "components/CardNFT";
import { Asset,  AssetData} from "../helpers/types";
import { getAssetsFromCollectionFromBackend, getAssetsFromCollectionFromBackendTest } from "../helpers/helpers";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionSliderSingleCollectionProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  policy?: string;
  path?: string;
}

const SectionSliderSingleCollection: FC<SectionSliderSingleCollectionProps> = ({
  policy,
  path,
}) => {
  const [data, setData] = useState<AssetData[]>([]);
  const assetItems = 4;

  useEffect(() => {
    const fetchData = async () => {
      if (!policy) {
        return;
      }

      try {
        const results = await getAssetsFromCollectionFromBackendTest(policy, 1, 4);
        setData(results as AssetData[]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [policy, assetItems]);

  return (
    <>
      <Heading>{policy ? `Explore The Collection` : "Explore The Collection"}</Heading>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10 z-10">
        {data.map((asset, index) => (
          <CardNFT
            id={asset.metadata.id}
            _id={asset._id}
            path={path}
            policy={policy}
            image={Array.isArray(asset.metadata.image) ? asset.metadata.image.join("") : asset.metadata.image}
            name={asset.metadata.name}
            src={asset.metadata.files && asset.metadata.files.length > 0 ? asset.metadata.files[0]?.src : asset.metadata.image}
            price={0}
            isListed={false}
            onIsLikedChange={(isLiked: boolean, assetId: string)=> {}}
            isLiked={false}
            likes={asset.likes || 0}
          />
        ))}
      </div>
      <div className="flex justify-center pt-8">
        <Link to={"/collection/" + path} className="">
          <ButtonPrimary className="z-10">
            View All Collection Items
          </ButtonPrimary>
        </Link>
      </div>
    </>
  );
};

export default SectionSliderSingleCollection;
