import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import InteractiveModal from "./InteractiveModal";

export interface CardNFTProps {
  className?: string;
  isLiked: boolean;
  id: string;
  _id: string;
  image: string;
  dbname?: string;
  name: string;
  src: Array<string> | string;
  path?: string;
  policy?: string;
  isListed: boolean;
  price: number;
  onIsLikedChange: (isLiked: boolean, assetId: string) => void;
  likes: number;
}

const CardNFT: FC<CardNFTProps> = ({
  className = "",
  isLiked,
  id,
  _id,
  dbname,
  image,
  name,
  src,
  path,
  policy,
  isListed,
  price,
  onIsLikedChange,
  likes
}) => {
  const [showIframe, setShowIframe] = React.useState(false);
  const [rawNFT, setRawNFT] = React.useState("");

  const [localIsLiked, setLocalIsLiked] = useState(isLiked);
  const [localLikes, setLocalLikes] = useState(likes);

  const handleMouseEnter = () => {
    setShowIframe(true);
  };

  const handleMouseLeave = () => {
    setShowIframe(false);
  };

  const handleAssetClick = (_id: string) => {
    // Store the clicked asset ID in session storage
    sessionStorage.setItem('clickedAssetId', _id);
    sessionStorage.setItem('collectionPath', path ?? '');
    //navigate("/" + path + "/" + _id);
  };

  const isIpfs = typeof src == 'string' && src.startsWith("ipfs")
  const isBase64Img = Array.isArray(src) && src[0].startsWith("data:image/");

  React.useEffect(() => {
    if (src && src.length > 0) {
      let result;
      if (Array.isArray(src)) {
        const rawNFTArray = src as string[];
        result = rawNFTArray.join('');
      } else {
        result = src;
      }
      // Custom awoken projects rule for pretty display
      if (policy ===
        "95c248e17f0fc35be4d2a7d186a84cdcda5b99d7ad2799ebe98a9865" ||
        policy ===
        "2b4ac45ffa2c8dca38354121bb581ce582819106c48920b4bcf3a109") {
        // Extract base64 content from the data URL
        const base64Content = result.split(',')[1];
        // Decode base64 string to get HTML content
        const decodedHTML = Buffer.from(base64Content, 'base64').toString('utf-8');
        // Modify html content to remove background style if it exists
        const modifiedHTML = decodedHTML.replace('body style="background:#000"', 'body');
        // Re-encode back to base64
        const modifiedBase64 = Buffer.from(modifiedHTML, 'utf-8').toString('base64');

        setRawNFT(`data:text/html;base64,${modifiedBase64}`);
      } else {
        setRawNFT(result)
      }
    }
  }, [policy, src]);

  const handleOnIsLikedChange = (newIsLiked: boolean) => {
    setLocalIsLiked(newIsLiked);
    setLocalLikes(prevLikes => newIsLiked ? prevLikes + 1 : prevLikes - 1);

    // Call the prop function to update the backend
    onIsLikedChange(newIsLiked, _id);
  }

  const handleModalOpen = () => {
    setShowIframe(false); // Set showIframe to false when the modal is opened
  };

  return (

    <div
      className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardNFT"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleAssetClick(_id)}
      id={_id}
    >

      <div className="relative flex-shrink-0">
        {/*<NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
            src={image}
            className="h-full w-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
      />*/}
        {src && showIframe && !isIpfs && !isBase64Img ? (
          <div className="flex w-full h-full overflow-hidden z-0 aspect-w-12 aspect-h-12 rounded-3xl fade-in">
            <iframe className="h-[105%] w-[105%] -top-2 -left-2 " src={rawNFT} title="asset" />
          </div>
        ) : (
          <NcImage
            containerClassName="flex w-full h-0 rounded-3xl overflow-hidden z-0 aspect-w-12 aspect-h-12 fade-in"
            src={image}
            className="h-full w-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
          />
        )}

        <InteractiveModal src={src} onOpenModal={handleModalOpen} />

        {/*
        <LikeButton
          liked={isLiked}
          className="absolute top-3 right-3 z-10 !h-9"
        /> */}
        <div className="absolute top-3 inset-x-3 flex"></div>
      </div>

      <div className="p-4 py-5 space-y-3">
        <h2 className={`text-lg font-medium`}>{name}</h2>

        <div className="w-2d4 w-full border-b border-neutral-100 dark:border-neutral-700"></div>

        <div className="flex justify-between items-end ">
          <Prices price={price} isListed={isListed} labelTextClassName="bg-white dark:bg-neutral-900 dark:group-hover:bg-neutral-800 group-hover:bg-neutral-50" />
          <div className="flex items-center text-sm text-neutral-500 dark:text-neutral-400">
            <LikeButton
              isLiked={localIsLiked}
              onIsLikedChange={handleOnIsLikedChange}
              likes={localLikes}
              className="z-10"
            />
          </div>
        </div>
      </div>

      <Link to={"/" + path + "/" + _id} className="absolute inset-0"></Link>
    </div>
  );
};

export default CardNFT;
