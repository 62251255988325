import React, { useState, useEffect } from 'react';
import { CollectionContext } from './CollectionContext';
import { User } from "helpers/types";
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import {
    HamburgerMenuIcon,
    CheckIcon,
} from '@radix-ui/react-icons';
import { Link } from "react-router-dom";
import { Toast } from 'flowbite-react';
import { FaTelegramPlane } from 'react-icons/fa';
import { ModalContent, ModalTitle, Select } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Modal, Button as ButtonMantine } from '@mantine/core';
import { deleteCollection, updateCollectionOwner } from '../helpers';

const ActionCell = ({ row }: { row: any }) => {
    const { fetchData } = React.useContext(CollectionContext);
    const path = row.original.path;
    const [isOpen, setIsOpen] = useState(false);
    const [opened, { open, close }] = useDisclosure(false);
    const [showOwnerToast, setShowOwnerToast] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { users } = React.useContext(CollectionContext);
    const [selectedUser, setSelectedUser] = useState<User>();

    const userData = users.map((user) => ({
        label: user.name,
        value: user.id,
    }));

    const collectionId = row.original.id;
    const collectionName = row.original.name;
    const collectionPolicy = row.original.policy;

    const handleChangeOwner = async () => {
        if (selectedUser && collectionId) {
            try {
                await updateCollectionOwner(selectedUser.id, selectedUser.name, collectionId);
                fetchData(); // refresh table data
                close(); // close modal
                console.log('Collection owner updated successfully');
                setShowOwnerToast(true);
                setTimeout(() => setShowOwnerToast(false), 4000);
            } catch (error) {
                console.error('Error updating collection owner:', error);
            }
        } else {
            console.error('User or collection not selected');
        }
    };

    const handleDelete = async () => {
        try {
            await deleteCollection(collectionPolicy);
            fetchData();
        } catch (error) {
            console.error("Error deleting collection:", error);
        }
    };

    return (
        <div className="font-medium text-left">
            <Modal.Root opened={opened} onClose={close} centered >
                <Modal.Overlay />
                <Modal.Content className=''>
                    <Modal.Header >
                        <Modal.Title >Change Collection Owner</Modal.Title>
                        <Modal.CloseButton />
                    </Modal.Header>
                    <Modal.Body>
                        <p className='text-sm'>You are changing the owner of collection:</p><p className='my-1'> {collectionName}</p>
                        <Select
                            data={userData}
                            placeholder="Search for a user"
                            searchable
                            searchValue={searchValue}
                            onSearchChange={setSearchValue}
                            onChange={(value) => {
                                // Find the selected user data from userData array
                                const selectedUserData = userData.find(user => user.value === value);
                                // If selectedUserData is found, create a partial User object
                                if (selectedUserData) {
                                    const selectedUser: Partial<User> = {
                                        id: selectedUserData.value,
                                        name: selectedUserData.label
                                    };
                                    setSelectedUser(selectedUser as User);
                                } else {
                                    setSelectedUser(undefined); // Reset selectedUser if user is not found
                                }
                            }}
                        />
                        <ButtonMantine className='mt-6' onClick={handleChangeOwner}>Change Owner</ButtonMantine>
                    </Modal.Body>
                </Modal.Content>
            </Modal.Root>
            <DropdownMenu.Root open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
                <DropdownMenu.Trigger asChild>
                    <button
                        className="rounded-full w-[35px] h-[35px] inline-flex items-center justify-center text-black dark:text-white  shadow-blackA4 outline-none hover:bg-violet3 "
                        aria-label="actions"
                    >
                        <HamburgerMenuIcon />
                    </button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Portal>
                    <DropdownMenu.Content
                        className="min-w-[220px] bg-white dark:bg-neutral-700 text-black dark:text-white rounded-md p-[5px] shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)] will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade"
                        sideOffset={5}
                    >
                        <Link to={`/collection/${path}`}>
                            <DropdownMenu.Item className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none ">
                                View Collection{' '}
                            </DropdownMenu.Item>
                        </Link>
                        <Link to={`/collection/${path}/edit`}>
                            <DropdownMenu.Item className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none">
                                Edit Details{' '}
                            </DropdownMenu.Item>
                        </Link>
                        <Link to={`/collection/${path}/admin`}>
                            <DropdownMenu.Item className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none">
                                Edit Filters{' '}
                            </DropdownMenu.Item>
                        </Link>
                        <span onClick={() => { open(); setIsOpen(false); }} className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none">
                            Change Owner
                        </span>
                        <DropdownMenu.Separator className="h-[1px] bg-slate-200 m-[5px]" />
                        <Link to={`/admin/collections/create`}>
                            <DropdownMenu.Item className="group text-[13px] hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none">
                                Add New Collection{' '}
                            </DropdownMenu.Item>
                        </Link>
                        <DropdownMenu.Item onClick={handleDelete} className="group text-[13px] text-red-500 hover:bg-primary-6000 hover:text-white leading-none rounded-[3px] flex items-center h-[25px] px-[5px] relative pl-[25px] select-none outline-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none">
                            Remove Collection{' '}
                        </DropdownMenu.Item>
                    </DropdownMenu.Content>
                </DropdownMenu.Portal>
            </DropdownMenu.Root>
            {showOwnerToast && (
                <div className="fixed top-4 right-4 z-50 flex items-center bg-gray-800 text-white rounded p-2">
                    <FaTelegramPlane className="h-5 w-5 text-cyan-600 dark:text-cyan-500" />
                    <div className="pl-2 p-4 text-sm font-normal">Owner Changed Successfully</div>
                </div>
            )}
        </div>

    );
}

export default ActionCell;