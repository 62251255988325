import { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Tabs, Toast } from 'flowbite-react';
import FormItem from 'components/FormItem';
import Input from 'shared/Input/Input';
import Textarea from 'shared/Textarea/Textarea';
import { Divider } from '@mantine/core';
import ImageUpload from 'components/ImageUpload';
import { HiAdjustments, HiClipboardList, HiUserCircle } from 'react-icons/hi';
import { doGet, doPost, doPut } from 'helpers/api_helper';
import { User } from '../../helpers/types';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { BadgeCheckIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { selectUser } from "app/auth/login/reducer";
import { useAppSelector } from "app/hooks";
import { fetchUserById } from 'helpers/helpers';
import { useNavigate } from 'react-router-dom';
import GeomBackground from 'components/Ui/BgGeom';
import { useAppDispatch } from "app/hooks";
import { updateUser } from "app/auth/login/reducer";


interface PageUserSettingsProps {
}

const initialState = {
    id: "",
    name: "",
    description: "",
    displayName: "",
    discord: "",
    twitter: "",
    artist: false,
    role: "USER"
};

const PageUserSettings = ({ }: PageUserSettingsProps) => {

    const userDetails: User | null = useAppSelector(selectUser);
    const authUser = localStorage.getItem('authUser');
    const [userData, setUserData] = useState<User | null>(null);
    const token = authUser ? JSON.parse(authUser).token : null;
    const [avatarImage, setAvatarImage] = useState<File | null>(null);
    const [bannerImage, setBannerImage] = useState<File | null>(null);
    const [user, setUser] = useState<User>(initialState);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [nameExists, setNameExists] = useState(false);
    const navigate = useNavigate();
    const [resetKey, setResetKey] = useState(0);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!userDetails) {
            return
        }
        const fetchData = async () => {
            try {
                const user = await fetchUserById(userDetails.id);
                setUserData(user);
                setUser(prevUser => ({
                    ...prevUser,
                    name: user?.name || prevUser.name,
                    displayName: user?.displayName || prevUser.displayName,
                    description: user?.description || prevUser.description,
                    twitter: user?.twitter || prevUser.twitter,
                    discord: user?.discord || prevUser.discord,
                }));
            } catch (error) {
                setErrorMessage('Failed to fetch user data');
            }
        };
        fetchData();
    }, [userDetails]);

    const handleAvatarImageSelect = (file: File) => {
        setAvatarImage(file);
    };

    const handleBannerImageSelect = (file: File) => {
        setBannerImage(file);
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setErrorMessage("");
        setSuccessMessage("");

        try {
            const formData = new FormData();
            if (bannerImage !== null) {
                formData.append("bannerImage", bannerImage);
            }

            if (avatarImage !== null) {
                formData.append("avatarImage", avatarImage);
            }

            if (nameExists) {
                setErrorMessage("User Name Exists! Please choose another name.");
                return
            }
            formData.append("id", userDetails?.id || "");
            formData.append("name", user.name || "");
            formData.append("displayName", user.displayName || "");
            formData.append("description", user.description || "");
            formData.append("discord", user.discord || "");
            formData.append("twitter", user.twitter || "");

            const response = await doPut("/api/user",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }, true);

            if (response.data.status === 'success') {
                setSuccessMessage(response.data.message);
                setIsSubmitted(true);

                // Update local storage and Redux store
                const updatedUser = response.data.data;
                const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');
                authUser.user = updatedUser;
                localStorage.setItem('authUser', JSON.stringify(authUser));

                // Dispatch the updateUser action
                dispatch(updateUser(updatedUser));

                navigate(`/${updatedUser.name}?t=profileUpdated`);
            } else {
                setErrorMessage(response.data.message);
            }
        } catch (error: any) {
            console.error(error);
            if (error.response) {
                const errorMessage: string = error.response.data.message || "An error occurred while updating the profile.";
                setErrorMessage(errorMessage);
            } else {
                setErrorMessage("An unexpected error occurred. Please try again.");
            }
        }
    };

    const handleChange = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;
        if (name === 'name') {
            setUser({ ...user, [name]: value.toLowerCase() });
            const exists = await checkUserNameExists(value.toLowerCase());
            setNameExists(exists);
        } else {
            setUser({ ...user, [name]: value });
        }
    };

    const checkUserNameExists = async (name: string): Promise<boolean> => {
        try {
            const response = await doGet(`/api/user/name/${name}`);
            if (response.data.status === 'success') {
                return true;
            } else if (response.data.status === 'error') {
                return false;
            } else {
                console.error('Error checking user name existence:', response.data.message);
                return false;
            }
        } catch (error) {

            return false;
        }
    };


    useEffect(() => {
        if (isSubmitted) {
            const timer = setTimeout(() => {
                setIsSubmitted(false);
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [isSubmitted]);

    const heading = "User Settings";
    const subtitle = "Edit your profile details";

    return (
        <>
            <Helmet>
                <title>Cardano Art - Edit Collection</title>
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon h-24 2xl:h-28 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
                data-nc-id="HeadBackgroundCommon"
            >
                <GeomBackground type="grid_small" heading={heading} subtitle={subtitle} />
            </div>
            <div className="container mt-12 space-y-16 lg:space-y-28">
                <main>
                    <Tabs.Group aria-label="Default tabs" style="underline" className="my-3">
                        <Tabs.Item active title="Profile" icon={HiUserCircle}>
                            <form
                                onSubmit={handleSubmit}
                                className="mt-1"
                                encType="multipart/form-data"
                            >
                                <div className="grid grid-cols-1 md:gap-4 md:grid-cols-2 ">
                                    <FormItem label="Username" className="my-3">
                                        <div>
                                            <Input
                                                type="text"
                                                id="name"
                                                name="name"
                                                placeholder="username"
                                                value={user.name}
                                                onChange={handleChange}
                                            />
                                            <p className="text-xs mt-1">Your unique identifier cardano.art/(username)</p>
                                            {nameExists && <p className="text-red-500">Username already exists!</p>}
                                        </div>
                                    </FormItem>
                                    <FormItem label="Display Name" className="my-3">
                                        <Input
                                            type="text"
                                            id="displayName"
                                            name="displayName"
                                            placeholder="Display Name"
                                            value={user.displayName}
                                            onChange={handleChange}
                                        />
                                    </FormItem>
                                </div>
                                <FormItem label="Profile Description" className="my-3 max-w-xxl">
                                    <Textarea
                                        name="description"
                                        id="description"
                                        rows={6}
                                        className="mt-1.5"
                                        placeholder="Tell us about yourself"
                                        value={user.description}
                                        onChange={handleChange}
                                    />
                                </FormItem>
                                <div className="flex flex-wrap">
                                    <FormItem label="Discord Name" className="w-full my-3 pr-3 lg:w-1/3">
                                        <div className="flex">
                                            <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                                @
                                            </span>
                                            <Input
                                                type="text"
                                                name="discord"
                                                id="discord"
                                                className="!rounded-l-none"
                                                placeholder="discord#1234"
                                                value={user.discord}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </FormItem>
                                    <FormItem label="Twitter Name" className="w-full my-3 lg:px-3 lg:w-1/3">
                                        <div className="flex">
                                            <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                                @
                                            </span>
                                            <Input
                                                type="text"
                                                name="twitter"
                                                id="twitter"
                                                className="!rounded-l-none"
                                                placeholder=""
                                                value={user.twitter}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </FormItem>
                                </div>
                                <Divider my="xs" label="Edit Avatar or Banner" labelPosition="center" variant="dashed" />
                                <div className="flex flex-wrap gap-4">
                                    <div className="w-full md:w-1/3 my-4">
                                        <FormItem
                                            label="Avatar Image"
                                            desc="Image files only, GIFs are allowed. Ideal size 500 x 500"
                                        >
                                            <ImageUpload
                                                name="avatarImage"
                                                onFileSelect={(file) => handleAvatarImageSelect(file)}
                                                key={`avatar-${resetKey}`}
                                            />
                                        </FormItem>
                                    </div>
                                    <div className="w-full md:w-1/3 my-4">
                                        <FormItem
                                            label="Banner Image"
                                            desc="Image files only, GIFs are allowed. Ideal size 1920 x 500"
                                        >
                                            <ImageUpload
                                                name="bannerImage"
                                                onFileSelect={(file) => handleBannerImageSelect(file)}
                                                key={`banner-${resetKey}`}
                                            />
                                        </FormItem>
                                    </div>
                                </div>
                                <ButtonPrimary
                                    type="submit"
                                    className="mt-4"
                                > Update Profile </ButtonPrimary>
                            </form>
                            {isSubmitted && (
                                <div className="fixed bottom-4 right-4 z-10">
                                    <Toast className="bg-red-500">
                                        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                                            <BadgeCheckIcon className="h-5 w-5" />
                                        </div>
                                        <div className="ml-3 text-sm font-normal text-white px-3">
                                            ...
                                        </div>
                                        <Toast.Toggle />
                                    </Toast>
                                </div>
                            )}
                            {successMessage && (
                                <div className="fixed bottom-4 right-4 z-10">
                                    <Toast className="bg-green-500">
                                        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                                            <BadgeCheckIcon className="h-5 w-5" />
                                        </div>
                                        <div className="ml-3 text-sm font-normal text-white px-3">
                                            {successMessage}
                                        </div>
                                        <Toast.Toggle onClick={() => setSuccessMessage("")} />
                                    </Toast>
                                </div>
                            )}
                            {errorMessage && (
                                <div className="fixed bottom-4 right-4 z-10">
                                    <Toast className="bg-red-500">
                                        <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-green-800 dark:text-red-200">
                                            <ExclamationCircleIcon className="h-5 w-5" />
                                        </div>
                                        <div className="ml-3 text-sm font-normal text-white px-3">
                                            {errorMessage}
                                        </div>
                                        <Toast.Toggle onClick={() => setErrorMessage("")} />
                                    </Toast>
                                </div>
                            )}
                        </Tabs.Item>
                    </Tabs.Group>
                </main>
            </div></>
    );
}

export default PageUserSettings;
