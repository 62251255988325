import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CollectionFilter } from 'helpers/helpers';

export interface FilterState {
    searchQuery: string;
    priceRange: [number, number];
    sortOrder: string;
    collectionFilter: CollectionFilter;
  }

const initialState: FilterState = {
  searchQuery: '',
  priceRange: [0, 1000],
  sortOrder: 'price:asc',
  collectionFilter: { metadata: {} },
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setSearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    setPriceRange: (state, action: PayloadAction<[number, number]>) => {
      state.priceRange = action.payload;
    },
    setSortOrder: (state, action: PayloadAction<string>) => {
      state.sortOrder = action.payload;
    },
    setCollectionFilter: (state, action: PayloadAction<CollectionFilter>) => {
      state.collectionFilter = action.payload;
    },
  },
});

export const { setSearchQuery, setPriceRange, setSortOrder, setCollectionFilter } = filterSlice.actions;
export default filterSlice.reducer;