import { useQuery } from '@tanstack/react-query';
import { fetchUserByName } from 'helpers/helpers';
import { useNavigate } from 'react-router-dom';

export const useUserData = (userName: string | undefined) => {
  const navigate = useNavigate();

  return useQuery({
    queryKey: ['user', userName],
    queryFn: async () => {
      if (!userName) {
        throw new Error('Username is required');
      }
      const user = await fetchUserByName(userName);
      if (!user) {
        navigate('/404');
        throw new Error('User not found');
      }
      return user;
    },
    enabled: !!userName,
  });
};