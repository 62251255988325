import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonOutline extends ButtonProps {}

const ButtonOutline: React.FC<ButtonOutline> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
      className={`border border-transparent bg-transparent  ${className}`}
      {...args}
    />
  );
};

export default ButtonOutline;
