import React, { FC } from "react";
import { Spinner } from "flowbite-react";

export interface LoadingSpinnerProps {
}

const LoadingSpinner: FC<LoadingSpinnerProps> = () => {
  return (
    <div className="container relative top-0 left-0 w-full h-screen flex flex-col items-center justify-center bg-opacity-50">
      <Spinner aria-label="Loading" size="xl" />
      <div className="text-lg text-gray-700 mt-2">
        <span>Loading</span>
      </div>
    </div>
  );
};

export default LoadingSpinner;