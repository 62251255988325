import { PayloadAction } from '@reduxjs/toolkit';
import { createAppSlice } from 'app/createAppSlice'
import { doPost } from 'helpers/api_helper';
import { User } from 'helpers/types';
import { socket } from 'helpers/websocket';
// import type { DataSignature} from '@mesh';

export interface UserSliceState {
  user: User | null
  wallet: any
  token: string
}

const initialState: UserSliceState = {
  user: null,
  wallet: {},
  token: ""
}

export const loginSlice = createAppSlice({
  name: "login",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: create => ({
    login: create.asyncThunk(
      async (sig: any) => {
        const res = await doPost("/api/login", sig, {})

        console.log(res.data);

        localStorage.setItem("authUser", JSON.stringify({
          user: res.data.user,
          wallet: res.data.wallet,
          token: res.data.token,
        }))

        return res.data
      },
      {
        pending: state => {
        },
        fulfilled: (state, action) => {
          state.user = action.payload.user || {}
          state.wallet = action.payload.wallet || {}
          state.token = action.payload.token || ""
        },
        rejected: state => {
        },
      },
    ),
    logout: create.reducer(state => {
      console.log("reducer logout")
      localStorage.removeItem("authUser")
      state.user = null
      state.wallet = {}
      state.token = ""
      socket.disconnect()
    }),

    setLoginData: create.reducer((state, action: PayloadAction<UserSliceState>) => {
      state.user = action.payload.user
      state.wallet = action.payload.wallet || {}
      state.token = action.payload.token || ""
    }),
    updateUser: create.reducer((state, action: PayloadAction<User>) => {
      state.user = action.payload;
    }),
  }),

  selectors: {
    selectUser: s => s.user,
    selectWallet: s => s.wallet,
  },

})

export const { login, logout, setLoginData, updateUser } = loginSlice.actions

export const { selectUser, selectWallet } = loginSlice.selectors

export default loginSlice.reducer