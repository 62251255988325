import React from 'react';
import { Collection, User } from "helpers/types";

export const CollectionContext = React.createContext<{
    data: Collection[];
    users: User[];
    fetchData: () => void;
    fetchUsers: () => void;
}>({
    data: [],
    users: [],
    fetchData: () => { },
    fetchUsers: () => { },
});