import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import NcModal from "shared/NcModal/NcModal";
//import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import { useWallet, useWalletList } from "@meshsdk/react";
import { useAppDispatch } from "app/hooks";
import { login } from "app/auth/login/reducer";
import { useNavigate } from 'react-router-dom';

export interface PageConnectWalletProps {
  className?: string;
}

const PageConnectWallet: FC<PageConnectWalletProps> = ({ className = "" }) => {
  const [showModal, setShowModal] = useState(false);

  const wallets = useWalletList();
  const wallet = useWallet()
  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const connectWallet = async (walletName: string) => {
    console.log("connect now", walletName);

    await wallet.connect(walletName)

    // closeModal()
  }

  console.log(wallets);


  useEffect(() => {
    if (!wallet.connected) {
      return
    }
    console.log("connected", wallet.name);
    loginWithWallet()
  }, [wallet.connected])

  const getUserFromLocalStorage = () => {
    const authUserString = localStorage.getItem('authUser');
    if (authUserString) {
      try {
        return JSON.parse(authUserString);
      } catch (error) {
        console.error('Error parsing authUser from localStorage:', error);
      }
    }
    return null;
  }

  const loginWithWallet = async () => {
    const address = await wallet.wallet.getUsedAddress()
  
    try {
      const p = await wallet.wallet.signData(address.to_bech32(), "Sign message to login")
      await dispatch(login(p))
      
      // Get user data from local storage
      const userData = getUserFromLocalStorage();
      
      if (userData && userData.user && userData.user.name) {
        // Redirect to profile page using the user's name
        navigate(`/${userData.user.name}`);
      } else {
        // Fallback to homepage if name is not available
        navigate('/');
      }
      
      setShowModal(false); // Close the modal
    } catch (error) {
      console.log(error);
    }
  }

  const renderContent = () => {
    return (
      <form action="#">
        <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
          Connecting to your wallet
        </h3>
        <span className="text-sm">
          Please follow the pop-up from your wallet to sign in.
        </span>
      </form>
    );
  };

  return (
    <div
      className={`nc-PageConnectWallet ${className} h-screen`}
      data-nc-id="PageConnectWallet"
    >
      <Helmet>
        <title>Cardano Art - Connect Wallet</title>
      </Helmet>
      <div className="h-full container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Connect your wallet.
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              Connect with one of our available wallet providers or create a new
              one. If no wallets appear please ensure you have a wallet installed in your browser.
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <div className="space-y-3">
              {wallets.map((wallet) => (
                <div
                  key={wallet.name}
                  onClick={() => {
                    connectWallet(wallet.name)
                    setShowModal(true)
                  }}
                  typeof="button"
                  tabIndex={0}
                  className="relative rounded-xl hover:shadow-lg hover:bg-neutral-50 border 
                border-neutral-200 dark:border-neutral-700 px-3 sm:px-5 py-4 cursor-pointer flex 
                focus:outline-none focus:shadow-outline-blue focus:border-blue-500 dark:bg-neutral-800 
                dark:text-neutral-100 dark:hover:bg-neutral-900 dark:hover:text-neutral-200"
                >
                  <div className="flex items-center w-full">
                    <NcImage
                      src={wallet.icon}
                      containerClassName="flex-shrink-0 w-10 h-10 sm:w-14 sm:h-14 p-2 sm:p-3 bg-white rounded-full overflow-hidden shadow-lg"
                    />
                    <div
                      className={`ml-4 sm:ml-8 font-semibold text-xl sm:text-2xl `}
                    >
                      {wallet.name}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* ---- */}
            <div className="pt-2 ">
              <ButtonPrimary href={"/"} className="flex-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 12H3.67004"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">Go Back Home</span>
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>

      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-md"
        onCloseModal={() => setShowModal(false)}
        modalTitle="Connect Wallet"
      />
    </div>
  );
};

export default PageConnectWallet;
