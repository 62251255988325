import React, { useState, useEffect } from "react";

export interface LikeButtonProps {
  className?: string;
  isLiked: boolean;
  onIsLikedChange: (isLiked: boolean) => void;
  likes: number
}

const LikeButton: React.FC<LikeButtonProps> = ({
  className,
  isLiked,
  onIsLikedChange,
  likes
}) => {
  const [localIsLiked, setLocalIsLiked] = useState(isLiked);
  const [localLikes, setLocalLikes] = useState(likes);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    if (!isUpdating) {
      setLocalIsLiked(isLiked);
      setLocalLikes(likes);
    }
  }, [isLiked, likes, isUpdating]);

  const handleLikeClick = () => {
    if (isUpdating) return;

    setIsUpdating(true);
    const newIsLiked = !localIsLiked;
    
    // Update local state immediately for responsive UI
    setLocalIsLiked(newIsLiked);
    setLocalLikes(prevLikes => newIsLiked ? prevLikes + 1 : prevLikes - 1);

    // Notify parent component
    onIsLikedChange(newIsLiked);

    // Allow next update after a short delay
    setTimeout(() => setIsUpdating(false), 500);
  };

  return (
    <button
      className={`px-2 px-3.5 h-10 flex items-center justify-center rounded-full text-white ${className}`}
      onClick={handleLikeClick}
      disabled={isUpdating}
    >
      <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
        <path
          d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z"
          stroke="#000"
          fill={localIsLiked ? "#ef4444" : "none"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="stroke-black dark:stroke-white"
        />
      </svg>
      <span className="ml-2 text-sm text-medium text-neutral-500 dark:text-white">{localLikes}</span>
    </button>
  );
};

export default LikeButton;