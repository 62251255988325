import React from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import { Helmet } from "react-helmet";
import SectionHero from "components/SectionHero/SectionHero";
import Vector1 from "images/Vector1.png";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionGridFeatureNFT from "./SectionGridFeatureNFT";
import Hero from "./MeetArtist";

function PageHome() {
  const arr = [
    "2b4ac45ffa2c8dca38354121bb581ce582819106c48920b4bcf3a109",
    "95c248e17f0fc35be4d2a7d186a84cdcda5b99d7ad2799ebe98a9865",
    "bb3ce45d5272654e58ad076f114d8f683ae4553e3c9455b18facfea1",
    "440606b6a9de303b6886bf7a9e9944f528cabf99096668000665918b",
    "2bc31b7881acbd6558b680d42248e201d9c7a2931e278b95e96daab8",
    "623e64fa1dbe49fcda34b6133199eef48912b14a98e7aa29b8792fcf",
    "f3a65485a9201e979e03aa4fa9f1d544332b7b8440bcd4aa4c6f7d1d",
    "0e14267a8020229adc0184dd25fa3174c3f7d6caadcb4425c70e7c04",
    "5f19f28bf48e3c15b2454a0e3c14c8ba19f7ee399a978a9bc5242c46",
    "91acca0a2614212d68a5ae7313c85962849994aab54e340d3a68aabb",
    "8a38146c49decd8f4a26b3b2c1ffaed696a503189b595bc4ee4e6056"
  ];

  const randomPolicy = arr[Math.floor(Math.random() * arr.length)];
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Cardano Art - On-chain &#38; generative NFT collections</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-20 mt-12 mb-20 sm:space-y-24 sm:my-24 lg:space-y-32 lg:my-32">
        {/* SECTION HERO */}
        <SectionHero
          className="pb-10"
          heading={
            <span>
              Explore
              <br />on-chain &#38;  generative {` `}
              <span className="relative pr-3">
                <img
                  className="w-full absolute bottom-3 -left-1"
                  src={Vector1}
                  alt="Vector1"
                />
                <span className="relative">NFT Collections on Cardano</span>
              </span>
            </span>
          }
        />
        {/* SECTION 2 */}
        <SectionHowItWork />
      </div>
      <div className=" container relative py-20 lg:py-28">
        <SectionGridFeatureNFT />
      </div>
      {/* SECTION LAERGE SLIDER */}
      <div className="bg-neutral-100/80 dark:bg-black/20 py-20 lg:py-32">
        <div className="container">
          <SectionLargeSlider policy={randomPolicy} />
        </div>
      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION 1 */}
        <SectionSliderCategories />
        {/* SECTION */}

        <div className="relative py-20 lg:py-28">
          <BackgroundSection />
          <Hero />
        </div>


        {/* SECTION 
        <SectionGridAuthorBox boxCard="box4" /> */}
      </div>
    </div>
  );
}

export default PageHome;
