import { Helmet } from "react-helmet";
import GeomBackground from "components/Ui/BgGeom";
import { Link } from "react-router-dom";


const Team = () => {
    return (
        <>
            <Helmet>
                <title>Cardano Art - The Team</title>
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon h-24 2xl:h-28 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
                data-nc-id="HeadBackgroundCommon"
            >
                <GeomBackground
                    type="AwokenBackground"
                />
            </div>
            <div className="container mx-auto px-4 py-16 max-w-6xl">
                <main className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
                    <div className="p-8 md:p-12">
                        <h1 className="text-3xl font-bold text-center text-gray-800 dark:text-white mb-8">The Team</h1>
                        <div className="prose dark:prose-invert max-w-3xl mx-auto mb-12">
                            <p className="text-lg text-gray-600 dark:text-gray-300 text-center">
                                Our passionate team of three is dedicated to exploring the exciting intersection of art and blockchain technology. As firm believers in the power of the Cardano blockchain, we are working tirelessly to push the boundaries of what's possible with on-chain art. Each team member brings unique skills and a shared enthusiasm to the project. We are driven by a vision of making art more accessible, secure, and transparent through leveraging the capabilities of blockchain. Join us on this journey as we revolutionize the art world one block at a time.
                            </p>
                        </div>

                        <div className="grid gap-8 md:grid-cols-3">
                            {[
                                { name: "Mario H", role: "Project Lead", image: "/images/team1.png" },
                                { name: "Syn", role: "Platform Developer / On-Chain Artist", image: "/images/team2.png" },
                                { name: "Daennis", role: "Blockchain Developer", image: "/images/team3.png" }
                            ].map((member, index) => (
                                <div key={index} className="bg-gray-50 dark:bg-gray-700 rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-xl hover:scale-105">
                                    <img className="w-full h-64 object-cover object-center" src={member.image} alt={member.name} />
                                    <div className="p-6">
                                        <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-2">{member.name}</h3>
                                        <p className="text-sm text-gray-600 dark:text-gray-300">{member.role}</p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="mt-12 text-center">
                            <Link to={`/support`}>
                                <div className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-300">
                                    Join Our Team
                                    <svg className="ml-2 -mr-1 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" />
                                    </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default Team;