import React, { FC, useRef } from "react";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "./LikeSaveBtns";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import VerifyIcon from "components/VerifyIcon";
import collectionPng from "images/avatars/StellarProfile.webp";
import LikeButton from "components/LikeButton";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonGrad from "shared/Button/ButtonGrad";
import { fetchAssetData, fetchAssetListing, fetchCollectionData, fetchUserById, fetchUserByName, Listing } from "../../helpers/helpers";
import { Asset, AssetData, Collection, User } from "../../helpers/types";
import { Link, useParams, useSearchParams } from "react-router-dom";
import SectionSliderSingleCollection from "components/SectionSliderSingleCollection";
import { doGet } from "helpers/api_helper";
import { Schema, createSchema } from "genson-js/dist";
import CollectionTags from "components/CollectionTags";
import NcImage from "shared/NcImage/NcImage";
import { Tooltip } from "flowbite-react";

export interface NftDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
  nassetname?: string;
}

interface Metadata {
  _id: string;
  red: {
    path: string;
    type: string;
    numberFilter: {
      range: {
        start: number;
        end: number;
      };
    };
  };
}

type DynamicAssetData = {
  [key: string]: any;
};

type AssetStructure = {
  [key: string]: {
    path: string;
    type: string;
    numberFilter?: {
      range: {
        start: number;
        end: number;
      };
    };
    stringFilter?: {
      options: string[];
      optionsSet: Record<string, never>;
    };
  };
};

interface ListingResponse { [key: string]: { policy: string, price: number } }

const NftDetailPage: FC<NftDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const [rawNFT, setRawNFT] = React.useState("");
  let [searchParams] = useSearchParams();

  // Grab the token data
  const [data, setAssetData] = React.useState<any | null>(null);

  // get collection data
  const { path } = useParams<{ path: string }>();
  const { assetId } = useParams<{ assetId: string }>();
  const [collectionData, setCollectionData] = React.useState<Collection | null>(null);
  const [metadataStructure, setMetadataStructure] = React.useState<Metadata | null>(null);
  const [dynamicValues, setDynamicValues] = React.useState<Array<{ key: string; value: any }>>([]);
  const [assetListingData, setAssetListingData] = React.useState<Listing | null>(null);
  const [userData, setUserData] = React.useState<User | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const searchType = 'path';
        const actualValue = path || '';
        const fetchedData = await fetchCollectionData(searchType, actualValue);
        if (fetchedData === null) {
          console.error('Failed to fetch collection data');
          return;
        }
        setCollectionData(fetchedData);
        console.log('Fetched Collection Data:', fetchedData);

        const user = await fetchUserById(fetchedData.ownerId || '');
        setUserData(user);
        console.log('Fetched User Data:', userData);

        const policyId = fetchedData.policy;

        // Fetch asset data
        let fetchedAssetData;
        try {
          fetchedAssetData = await fetchAssetData(policyId, assetId || "");
          setAssetData(fetchedAssetData);
          console.log('Fetched Asset Data:', fetchedAssetData);
        } catch (error) {
          console.error('Error fetching asset data:', error);
          return;
        }

        // Fetch asset listing data
        let assetListingData: Listing | null;
        try {
          assetListingData = await fetchAssetListing(policyId, assetId || "");
          console.log('Fetched Asset Listing Data:', assetListingData);
          setAssetListingData(assetListingData);
        } catch (error) {
          console.error('Error fetching asset listing data:', error);
          return;
        }

        if (metadataStructure === null) {
          await loadMetadata(policyId);
        }
        if (metadataStructure !== null) {
          await renderAssetData(fetchedAssetData, metadataStructure);
          console.log("New data:", dynamicValues);
        } else {
          console.error('Metadata Structure Waiting. . .');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [path, metadataStructure, assetId]);

  const loadMetadata = async (policyId: any) => {
    try {
      const response = await doGet<Metadata>(`/api/collection/${policyId}/filter`);
      if (response !== null) {
        setMetadataStructure(response.data);
        console.log('Fetched Metadata:', response.data);
      } else {
        console.error('Metadata is null.');
      }
    } catch (error) {
      console.error('Error fetching Metadata:', error);
    }
  };

  const getDataAtPath = (data: DynamicAssetData, path: string | undefined): any => {
    if (path === undefined || typeof path !== 'string') {
      console.error('Invalid path:', path);
      return undefined;
    }

    const pathSegments = path.split('.').filter(Boolean);

    return pathSegments.reduce((obj, key) => {
      if (!obj) return undefined;

      console.log('Current Object:', obj);
      console.log('Current Key:', key);

      // Check if the key ends with '[]' indicating an array path
      const isArrayPath = key.endsWith('[]');
      const arrayKey = isArrayPath ? key.slice(0, -2) : key;

      if (isArrayPath) {
        console.log('isArrayPath:', isArrayPath);
        console.log('ArrayKey:', arrayKey);
        console.log('Current Object[arrayKey]:', obj[arrayKey]);

        // Handle nested structure
        if (Array.isArray(obj[arrayKey]) || (obj[arrayKey] && isArrayPath)) {
          return obj[arrayKey];
        } else if (obj.metadata && obj.metadata[arrayKey]) {
          return obj.metadata[arrayKey];
        } else {
          console.error(`Array at key '${key}' is empty or not found.`);
          return undefined;
        }
      } else if (Array.isArray(obj)) {
        const resultArray = obj.flatMap((item) => getDataAtPath(item, key));
        return resultArray.some((value) => value !== undefined) ? resultArray : undefined;
      } else if (key in obj) {
        return obj[key];
      } else if (obj.hasOwnProperty('metadata') && typeof obj.metadata === 'object') {
        // Handle dynamic array key for metadata
        const dynamicArrayKey = key.endsWith('[]') ? key.slice(0, -2) : key;
        if (obj.metadata[dynamicArrayKey]) {
          return obj.metadata[dynamicArrayKey];
        } else {
          console.error(`Array at key '${key}' is empty or not found in metadata.`);
          return undefined;
        }
      } else {
        console.error(`Key '${key}' not found in object.`);
        return undefined;
      }
    }, data);
  };

  const renderAssetData = (data: AssetData, structure: AssetStructure | Metadata) => {
    if (!data || !structure || !Object.keys(structure).length) {
      return null;
    }

    const values = Object.entries(structure).map(([key, structureItem]) => {
      // Skip rendering if the key is "_id"
      if (key === '_id') {
        return null;
      }

      console.log('Structure Item:', structureItem);

      // Check if structureItem.path is defined before using it
      if (structureItem.path !== undefined) {
        const value = getDataAtPath(data, structureItem.path);
        return { key, value };
      } else {
        console.error('Path is undefined in Structure Item:', structureItem);
        return { key, value: undefined };
      }
    }).filter(Boolean) as { key: string; value: any }[];

    setDynamicValues(values);
  };

  // Get onchain string array ready for iframe
  React.useEffect(() => {
    if (data && data.metadata) {
      let rawNFTArray;
      if (data.metadata.files && data.metadata.files.length > 0) {
        const filesEntry = data.metadata.files[0];
        // Check if the first entry in 'files' is a base64 HTML document
        if (filesEntry && filesEntry.src && Array.isArray(filesEntry.src)) {
          // Assuming that if the first entry is an array, it's considered as a base64 HTML document
          rawNFTArray = filesEntry.src.join('');
        } else {
          // If not a base64 HTML document, use 'image'
          rawNFTArray = data.metadata.image;
        }
      } else if (data.metadata.image) {
        rawNFTArray = data.metadata.image;
      } else {
        console.error('No files or image found in metadata.');
        return;
      }

      let result;
      if (Array.isArray(rawNFTArray)) {
        result = rawNFTArray.join('');
      } else {
        result = rawNFTArray;
      }
      // Custom awoken projects rule for pretty display
      if (
        collectionData?.policy === "95c248e17f0fc35be4d2a7d186a84cdcda5b99d7ad2799ebe98a9865" ||
        collectionData?.policy === "2b4ac45ffa2c8dca38354121bb581ce582819106c48920b4bcf3a109"
      ) {
        // Extract base64 content from the data URL
        const base64Content = result.split(',')[1];
        // Decode base64 string to get HTML content
        const decodedHTML = Buffer.from(base64Content, 'base64').toString('utf-8');
        // Modify html content to remove background style if it exists
        const modifiedHTML = decodedHTML.replace('body style="background:#000"', 'body');
        // Re-encode back to base64
        const modifiedBase64 = Buffer.from(modifiedHTML, 'utf-8').toString('base64');

        setRawNFT(`data:text/html;base64,${modifiedBase64}`);
      } else {
        setRawNFT(result);
      }
    }
  }, [data]);

  // Generate a unique content ID for the iframe for react bug
  function generateContentId() {
    return Date.now().toString();
  }
  const idRef = useRef<string | null>(null);
  React.useEffect(() => {
    if (idRef.current === null) {
      idRef.current = generateContentId();
    }
  }, []);

  return (
    <div
      className={`nc-NftDetailPage  ${className}`}
      data-nc-id="NftDetailPage"
    >
      {/* MAIN */}
      <main className="container mt-11 flex ">
        <div className="w-full grid md:grid grid-cols-1 lg:grid-cols-2 gap-[1rem] md:gap-[5rem] lg:gap-5">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10 ">
            {/* HEADING */}
            <div className="relative h-full w-full aspect-w-12 aspect-h-12">
              {
                rawNFT.startsWith('ipfs://') || rawNFT.startsWith('data:image/') ? (
                  <NcImage
                    src={rawNFT}
                    containerClassName="aspect-w-12 aspect-h-12 rounded-3xl overflow-hidden"
                  />
                ) : (
                  <iframe
                    title="CNFT"
                    className="h-full w-full rounded-3xl overflow-hidden"
                    src={rawNFT}
                    key={idRef.current}
                  ></iframe>
                )
              }
            </div>
          </div>
          {/* SIDEBAR */}
          <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
            {/* ----------  ----------  */}
            <div className="space-y-5">
              <div className="flex justify-between items-center">
                <div className="top-0 right-0 flex flex-wrap justify-end pt-2  cursor-pointer ">
                  <CollectionTags tags={collectionData?.tags} clickable={true} />
                </div>
                <LikeSaveBtns />
              </div>
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {data && data.metadata && (
                  <span>{data.metadata.name || data.metadata.title || data.name || "Unknown Name"}</span>
                )}
              </h2>
              {/* ---------- ----------  */}
              <div className="flex flex-col sm:flex-row sm:items-center space-y-4 sm:space-y-0 sm:space-x-8 text-sm">
                <div className="flex items-center ">
                  {userData?.name ? (
                    <Link to={`/${userData?.name}`}>
                      <Avatar
                        imgUrl={userData?.avatarImage ? userData?.avatarImage : ''}
                        sizeClass="h-9 w-9"
                        radius="rounded-full"
                      />
                    </Link>
                  ) : (
                    <Avatar
                      imgUrl={userData?.avatarImage ? userData?.avatarImage : ''}
                      sizeClass="h-9 w-9"
                      radius="rounded-full"
                    />
                  )}
                  <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                    <span className="text-sm">Creator</span>
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                      {userData?.name ? (
                        <Link to={`/${userData?.name}`}>
                          <span>{userData?.name}</span>
                        </Link>
                      ) : (
                        <span>{collectionData?.owner}</span>
                      )}
                      <VerifyIcon iconClass="w-4 h-4" />
                    </span>
                  </span>
                </div>
                <div className="hidden sm:block h-6 border-l border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex items-center">
                  <Avatar
                    imgUrl={collectionData?.avatarpath ? collectionData.avatarpath : ''}
                    sizeClass="h-9 w-9"
                    radius="rounded-full"
                  />
                  <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 flex flex-col">
                    <span className="text-sm">Collection</span>
                    <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                      <span><Link to={`/collection/${collectionData?.path}`}>{collectionData?.name}</Link></span>
                      <VerifyIcon iconClass="w-4 h-4" />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <Disclosure defaultOpen as="div" className="mt-5 md:mt-8 rounded-2xl">
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left   rounded-lg  focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                    <span>Asset Tools</span>
                    <ChevronUpIcon
                      className={`${open ? "transform rotate-180" : ""
                        } w-5 h-5 text-neutral-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel
                    className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                    as="div"
                  >
                    <div className="grid grid-cols-2 gap-2 mb-4 justify-center  md:grid-cols-4 lg:grid-cols-4">
                      <div className="justify-self-center">
                        <Tooltip content="Coming Soon">
                          <ButtonPrimary
                            className=""
                            rounded="rounded-md"
                            disabled={true}
                          >
                            <span className="text-xs text-center">
                              Create MP4 Video
                            </span>
                          </ButtonPrimary>
                        </Tooltip>
                      </div>

                      <div className="justify-self-center">
                        <Tooltip content="Coming Soon">
                          <ButtonPrimary
                            className="flex "
                            rounded="rounded-md"
                            disabled={true}
                          >
                            <span className="text-xs text-center">
                              Create PNG Image
                            </span>
                          </ButtonPrimary>
                        </Tooltip>
                      </div>
                      <div className="justify-self-center">
                        <Tooltip content="Coming Soon">
                          <ButtonPrimary
                            className="flex "
                            rounded="rounded-md"
                            disabled={true}
                          >
                            <span className="text-xs text-center">
                              Create Local Copy
                            </span>
                          </ButtonPrimary>
                        </Tooltip>
                      </div>
                      <div className="justify-self-center">
                        <Tooltip content="Coming Soon">
                          <ButtonPrimary
                            className="flex "
                            rounded="rounded-md"
                            disabled={true}
                          >
                            <span className="text-xs text-center">
                              Export Token Data
                            </span>
                          </ButtonPrimary>
                        </Tooltip>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <div className="rounded-2xl">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                      <span>Description</span>
                      <ChevronUpIcon
                        className={`${open ? "transform rotate-180" : ""
                          } w-5 h-5 text-neutral-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel
                      className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400 mb-5"
                      as="p"
                    >
                      {collectionData?.description}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            {/* ---------- metadata ----------  */}
            <div className="w-full rounded-2xl flex flex-col mb-5">
              {assetListingData && (
                <Disclosure defaultOpen={true}>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                        <span>Availability</span>
                        <ChevronUpIcon
                          className={`${open ? "transform rotate-180" : ""
                            } w-5 h-5 text-neutral-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel
                        className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                        as="div"
                      >
                        <div className="mb-3">
                          <div className="my-3">
                            <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                              <div className="flex-1 flex flex-col sm:flex-row items-baseline p-2 border-2 border-primary-500 dark:border-primary-500 rounded-xl relative">
                                <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-black dark:text-neutral-100">
                                  Current Price
                                </span>
                                <a href={`https://www.jpg.store/asset/${assetListingData.asset}`} target="_blank" rel="noopener noreferrer">
                                  <span className="text-xl xl:text-xl font-semibold text-black dark:text-white pl-3 block sm:inline">
                                    {assetListingData && typeof assetListingData.price === 'number' && !isNaN(assetListingData.price) ?
                                      Math.round(assetListingData.price) + " ADA" :
                                      "N/A"
                                    }
                                  </span>
                                  <span className="text-sm text-primary-500 dark:text-neutral-400 ml-3 mt-2 sm:mt-0 sm:ml-10 block sm:inline">
                                    View listing @ JPG Store
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              )}

            </div>
            {/* ---------- 9 ---------- 
        <div className="pt-9">
          <TabDetail />
        </div>
         */}
          </div>
        </div>
      </main>

      {/* OTHER SECTION */}

      <div className="container pb-24 lg:pb-32">
        {/* ---------- metadata ----------  */}

        <div className="relative my-5 pt-5 lg:py-5 mb-24">
          <div className="mt-12 rounded bg-gradient-to-r from-black via-primary-500 to-transparent-600 dark:from-primary-800 dark:via-slate-500 p-[2px] my-8 ">
            <span className="pl-2 block text-lg font-medium text-white p-[2px]">
              Metadata
            </span>
          </div>
          <div className="">
            <div>
              <div className="my-4 xl:mt-4 grid grid-cols-1 md:grid-cols-4 gap-4 sm:gap-4 xl:gap-6">
                {dynamicValues.map(({ key, value }, index) => (
                  <React.Fragment key={key || index}>
                    {Array.isArray(value) ? (
                      <>
                        {value.map((item, subIndex) => (
                          <div className="relative group" key={key || index + subIndex}>
                            <div className="absolute -inset-0.5 bg-gradient-to-r from-primary-500 to-secondary-200 dark:from-primary-500 dark:to-secondary-200 rounded-lg blur dark:blur-none opacity-10 dark:opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                            <div className="relative px-7 py-6 bg-white dark:bg-slate-800 ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                              <svg
                                className="w-8 h-8 text-black dark:text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"
                                ></path>
                              </svg>
                              <div className="space-y-2">
                                <p className="text-slate-800 dark:text-white">{key}</p>
                                <p className="font-medium text-base sm:text-sm sm:mt-3 truncate dark:text-white">{item}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="relative group" key={key || index}>
                        <div className="absolute -inset-0.5 bg-gradient-to-r from-primary-500 to-secondary-200 dark:from-primary-500 dark:to-secondary-200 rounded-lg blur dark:blur-none opacity-10 dark:opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200"></div>
                        <div className="relative px-7 py-6 bg-white dark:bg-slate-800 ring-1 ring-gray-900/5 rounded-lg leading-none flex items-top justify-start space-x-6">
                          <svg
                            className="w-8 h-8 text-black dark:text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M6.75 6.75C6.75 5.64543 7.64543 4.75 8.75 4.75H15.25C16.3546 4.75 17.25 5.64543 17.25 6.75V19.25L12 14.75L6.75 19.25V6.75Z"
                            ></path>
                          </svg>
                          <div className="space-y-2">
                            <p className="text-slate-800 dark:text-white">{key}</p>
                            <p className="font-medium text-base sm:text-sm sm:mt-3 truncate dark:text-white">{value}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}

              </div>
            </div>
          </div>
        </div>
        {/* SECTION 1 */}
        <div className="relative py-20 lg:py-28">
          <BackgroundSection />
          <SectionSliderSingleCollection policy={collectionData?.policy} path={path} />
        </div>
        <div className="relative py-24 lg:py-28">
          <SectionSliderCategories />
        </div>
      </div>
    </div>
  );
};

export default NftDetailPage;
