import React, { useState, useEffect } from "react";
import { Flowbite, Modal, Tooltip } from "flowbite-react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";
import NcImage from "shared/NcImage/NcImage";
import { baseIpfsUrl } from "helpers/helpers";

const InteractiveModalTrigger = React.memo(
  ({ src, onOpenModal }: { src: string | string[]; onOpenModal: () => void }) => {
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
        <div className="absolute top-3 right-3 z-20 h-9 cursor-pointer">
          <div
            onClick={() => {
              setOpenModal(true);
              onOpenModal(); // Call the onOpenModal callback
            }}
            className="w-8 h-8 flex items-center justify-center"
          >
            <ArrowsExpandIcon className="w-full h-full" />
          </div>
        </div>
        {openModal && <InteractiveModal src={src} onClose={() => setOpenModal(false)} />}
      </>
    );
  }
);

const InteractiveModal = ({ src, onClose }: { src: string | string[]; onClose: () => void }) => {
  const [iframeSrc, setIframeSrc] = useState("");

  useEffect(() => {
    if (src) {
      let img = "";
      if (Array.isArray(src)) {
        img = src.join("");
      } else {
        img = baseIpfsUrl + src.substring(7);
      }
      setIframeSrc(img);
    }
  }, [src]);

  return (
    <Modal dismissible={true} show={true} onClose={onClose}>
      <Modal.Header style={{ padding: 0, borderBottomWidth: 0 }} />
      <Modal.Body style={{ padding: 3 }}>
        <div className="flex aspect-w-8 aspect-h-8 lg:aspect-w-8 lg:aspect-h-8 w-full h-full overflow-hidden z-0">
          {iframeSrc && (iframeSrc.startsWith("https://") || iframeSrc.startsWith("data:image/")) ? (
            <NcImage
              src={iframeSrc}
              containerClassName="aspect-w-12 aspect-h-12 rounded-3xl overflow-hidden"
            />
          ) : (
            <iframe className="h-full w-full overflow-hidden" src={iframeSrc} title="asset" />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InteractiveModalTrigger;