import { Footer } from "flowbite-react";
import { BsDiscord, BsTwitter } from "react-icons/bs";
import React from "react";
import Logo from "shared/Logo/Logo";
const CustomFooter = () => {
  return (
    <Footer>
      <div className="w-full dark:bg-neutral-900/80">
        <div className="container">
          <div className="grid w-full grid-cols-2 gap-8 px-6 py-8 md:grid-cols-4">
            <div>
              <Footer.Title title="Browse" />
              <Footer.LinkGroup col>
                <Footer.Link href="/discover">Collections</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Creators" />
              <Footer.LinkGroup col>
                <Footer.Link href="/launchpad">Launchpad</Footer.Link>
                <Footer.Link href="/claimcollection">Claim Collection</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="About" />
              <Footer.LinkGroup col>
                <Footer.Link href="/terms">Terms &amp; Conditions</Footer.Link>
                <Footer.Link href="/privacy">Privacy Policy</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Contact" />
              <Footer.LinkGroup col>
                <Footer.Link href="/team">Team</Footer.Link>
                <Footer.Link href="/support">Get in Touch</Footer.Link>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider />
        <div className="container mb-4 w-full sm:flex sm:items-center sm:justify-between">
          <Footer.Copyright href="/" by="Cardano Art" year={new Date().getFullYear()} />
          <div className="mt-4 flex space-x-6 sm:mt-0 sm:justify-center">
            <Footer.Icon href="https://discord.com/invite/CFekZh8UU3" target="_blank" icon={BsDiscord} />
            <Footer.Icon href="https://twitter.com/CardanoArt" target="_blank" icon={BsTwitter} />
          </div>
        </div>
      </div>
    </Footer>
  );
};

export default CustomFooter;