"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn"

type Tab = {
    title: string;
    value: string;
    content?: string | React.ReactNode | any;
    onSetActive?: ()=>void;
};

export const Tabs = ({
    tabs: propTabs,
    containerClassName,
    activeTabClassName,
    tabClassName,
    contentClassName,
  }: {
    tabs: Tab[];
    containerClassName?: string;
    activeTabClassName?: string;
    tabClassName?: string;
    contentClassName?: string;
  }) => {
    const [active, setActive] = useState<Tab>(propTabs[0]);
    const [tabs, setTabs] = useState<Tab[]>(propTabs);
    const [hovering, setHovering] = useState(false);
  
    const moveSelectedTabToTop = (idx: number) => {
      const newTabs = [...propTabs];
      const selectedTab = newTabs.splice(idx, 1);
      newTabs.unshift(selectedTab[0]);
      setTabs(newTabs);
      setActive(newTabs[0]);
    };
  
    return (
      <div className="grid grid-rows-[auto,1fr] gap-8 w-full">
        <div
          className={cn(
            "flex flex-row items-center justify-start overflow-auto sm:overflow-visible no-visible-scrollbar max-w-full w-full",
            containerClassName
          )}
        >
          {propTabs.map((tab, idx) => (
            <button
              key={tab.title}
              onClick={() => moveSelectedTabToTop(idx)}
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
              className={cn("relative px-4 py-2 rounded-full", tabClassName)}
            >
              {active.value === tab.value && (
                <motion.div
                  layoutId="clickedbutton"
                  transition={{ type: "spring", bounce: 0.3, duration: 0.6 }}
                  className={cn(
                    "absolute inset-0 bg-gray-200 dark:bg-zinc-800 rounded-full ",
                    activeTabClassName
                  )}
                />
              )}
              <span className="relative block text-black dark:text-white">
                {tab.title}
              </span>
            </button>
          ))}
        </div>
        <div className="relative w-full min-h-[300px]">
          <FadeInDiv
            tabs={tabs}
            active={active}
            key={active.value}
            hovering={hovering}
            className={cn(contentClassName)}
          />
        </div>
      </div>
    );
  };


  export const FadeInDiv = ({
    className,
    tabs,
    hovering,
  }: {
    className?: string;
    key?: string;
    tabs: Tab[];
    active: Tab;
    hovering?: boolean;
  }) => {
    const isActive = (tab: Tab) => {
      return tab.value === tabs[0].value;
    };
  
    return (
      <div className="relative w-full">
        {tabs.map((tab, idx) => (
          <motion.div
            key={tab.value}
            layoutId={tab.value}
            style={{
              position: idx === 0 ? 'relative' : 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: -idx,
              opacity: idx < 3 ? 1 - idx * 0.2 : 0,
              pointerEvents: isActive(tab) ? 'auto' : 'none',
            }}
            animate={{
              y: isActive(tab) ? 0 : hovering ? idx * 20 : 0,
              scale: isActive(tab) ? 1 : 1 - idx * 0.05,
            }}
            transition={{
              duration: 0.3,
              ease: 'easeInOut'
            }}
            className={cn(
              "w-full",
              isActive(tab) ? 'relative' : 'absolute inset-0 overflow-hidden',
              className
            )}
          >
            <div className={isActive(tab) ? '' : 'invisible'}>
              {tab.content}
            </div>
          </motion.div>
        ))}
      </div>
    );
  };