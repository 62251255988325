import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Heading from "shared/Heading/Heading";
import Nav from "shared/Nav/Nav";
import NavItem from "shared/NavItem/NavItem";

export interface HeaderFilterSectionProps {
  className?: string;
  onTabChange: (tab: string) => void;
}

const HeaderFilterSection: FC<HeaderFilterSectionProps> = ({
  className = "mb-12",
  onTabChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(true);
  const [tabActive, setTabActive] = React.useState("Generative");

  function handleTabClick(item: string) {
    setTabActive(item);
    onTabChange(item);
  }

  return (
    <div className="md:flex md:items-center md:justify-center">
      <div className={className}>
        <div
          className="nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between">
          <div className="text-center w-full max-w-2xl mx-auto mb-4">
            <h2 className={`text-3xl md:text-4xl font-semibold text-transparent bg-clip-text dark:bg-gradient-to-r bg-black dark:from-primary-500 dark:to-secondary-400 `}>
              Featured Collections
            </h2>
          </div>
        </div>
        <Nav
          className="sm:space-x-2"
          containerClassName="relative flex w-full overflow-x-auto text-sm md:text-base hiddenScrollbar"
        >
          {["Generative", "Interactive", "Pixel", "Gaming", "Evolving", "PFP"].map(
            (item, index) => (
              <NavItem
                key={index}
                isActive={tabActive === item}
                onClick={() => handleTabClick(item)}
              >
                {item}
              </NavItem>
            )
          )}
        </Nav>
      </div>
    </div>
  );
};

export default HeaderFilterSection;
