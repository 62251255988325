import { UserContext } from './UserContext';
import { columns } from "./Columns";
import { User } from "helpers/types";
import { DataTable } from "./Table";
import React, { useState, useEffect } from "react";
import { fetchUsers } from "helpers/helpers";
import { deleteUser } from "../helpers";
import NavMenu from '../NavMenu';
import GeomBackground from 'components/Ui/BgGeom';
import { Helmet } from "react-helmet";

const AdminUsers = () => {
    const [data, setData] = useState<User[]>([]);
    const [records24H, setRecords24H] = useState<number>(0);
    const [records7D, setRecords7D] = useState<number>(0);
    const [records30D, setRecords30D] = useState<number>(0);
    const [recordsAllTime, setRecordsAllTime] = useState<number>(0);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const fetchedData = await fetchUsers();
            if (fetchedData !== null) {
                setData(fetchedData);

                const currentTime = new Date();
                const records24H = fetchedData.filter(user => {
                    const createdAt = user.created_at ? new Date(user.created_at) : null;
                    return createdAt && (currentTime.getTime() - createdAt.getTime()) / (1000 * 60 * 60) <= 24;
                }).length;
                setRecords24H(records24H);

                const records7D = fetchedData.filter(user => {
                    const createdAt = user.created_at ? new Date(user.created_at) : null;
                    return createdAt && (currentTime.getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24) <= 7;
                }).length;
                setRecords7D(records7D);

                const records30D = fetchedData.filter(user => {
                    const createdAt = user.created_at ? new Date(user.created_at) : null;
                    return createdAt && (currentTime.getTime() - createdAt.getTime()) / (1000 * 60 * 60 * 24) <= 30;
                }).length;
                setRecords30D(records30D);

                const recordsAllTime = fetchedData.length;
                setRecordsAllTime(recordsAllTime);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const handleDeleteUser = async (userId: string) => {
        try {
            await deleteUser(userId);
            // Assuming you have a function to refetch users after deletion
            fetchData();
        } catch (error) {
            console.error("Error deleting user:", error);
        }
    };
    const heading = `User Management`
    const subtitle = `Edit users and their details.`

    return (
        <><Helmet>
            <title>Cardano Art - Admin Panel</title>
        </Helmet><div
            className={`nc-HeadBackgroundCommon h-24 2xl:h-28 mt-12 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
            data-nc-id="HeadBackgroundCommon"
        >
                <NavMenu />
                <GeomBackground type="grid_small" heading={heading} subtitle={subtitle} />
            </div>
            <UserContext.Provider value={{ data, fetchData }}>
                <div className="container mx-auto py-20">
                    <DataTable columns={columns} data={data} />
                    <div>
                        <p>Users Created in Last 24 Hours: {records24H}</p>
                        <p>Users Created in Last 7 Days: {records7D}</p>
                        <p>Users Created in Last 30 Days: {records30D}</p>
                        <p>Total Users: {recordsAllTime}</p>
                    </div>
                </div>
            </UserContext.Provider></>
    );
}

export default AdminUsers;
