import React, { useState } from 'react';
import { ColumnDef } from "@tanstack/react-table";
import { User } from "helpers/types";
import { MoreHorizontal, ArrowUpDown, } from "lucide-react"
import { Button } from "../../components/Ui/Button"
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import {
    HamburgerMenuIcon,
    CheckIcon,
} from '@radix-ui/react-icons';
import { Link } from "react-router-dom";



export const columns: ColumnDef<User>[] = [
    {
        accessorKey: "name",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Handle
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => (
            <div className="pl-4">
                {row.original.name}
            </div>
        ),
    },
    {
        accessorKey: "displayName",
        header: "Full Name",
    },
    {
        header: "x",
        cell: ({ row }) => (
            <div className="pl-4">
                Profile Link
            </div>
        ),
    }
];
