import { columns } from "./Columns";
import { User } from "helpers/types";
import { DataTable } from "./Table";
import React, { useState, useEffect } from "react";
import { fetchUsers } from "helpers/helpers";
import GeomBackground from 'components/Ui/BgGeom';
import { Helmet } from "react-helmet";

const DiscoverArtists = () => {
    const [data, setData] = useState<User[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const fetchedData = await fetchUsers();
            if (fetchedData !== null) {
                setData(fetchedData);

               
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

  
    const heading = `Browse Artists`
    const subtitle = ``

    return (
        <><Helmet>
            <title>Cardano Art - Admin Panel</title>
        </Helmet><div
            className={`nc-HeadBackgroundCommon h-24 2xl:h-28 mt-12 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
            data-nc-id="HeadBackgroundCommon"
        >
                <GeomBackground type="grid_small" heading={heading} subtitle={subtitle} />
            </div>

                <div className="container mx-auto py-20">
                    <DataTable columns={columns} data={data} />
                </div>
           </>
    );
}

export default DiscoverArtists;
