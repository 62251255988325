import React, { useEffect, useRef } from "react";
import { createNoise3D } from "simplex-noise";

const MOBILE_THRESHOLD = 768; // Adjust this value as needed

export default function App() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const isMobile = window.innerWidth <= MOBILE_THRESHOLD;
    const scale = isMobile ? 0.5 : 1; // Reduce size for mobile
    const width = Math.floor(256 * scale);
    const height = Math.floor(256 * scale);

    canvas.width = width;
    canvas.height = height;

    const noise3D = createNoise3D();
    let t = 0;
    const dna = {
      dna1: 233, dna2: 269, dna3: 571, dna4: 771, dna5: 0.6,
      dna6: 49, dna7: 0.23, dna8: 0.83, dna9: 333, dna10: 775,
      dna11: 218, dna12: 39, dna13: 249
    };

    // Create a worker for processing
    workerRef.current = new Worker(
      URL.createObjectURL(new Blob([`
        self.onmessage = function(e) {
          const { width, height, noiseValues, dna } = e.data;
          const buffer = new Uint8ClampedArray(width * height * 4);
          
          for (let x = 0; x < width; x++) {
            for (let y = 0; y < height; y++) {
              const index = x + y * width;
              const r = noiseValues[index * 2];
              const g = noiseValues[index * 2 + 1];

              const data1 = (r || g) * dna.dna11;
              const data3 = dna.dna13;

              const bufferIndex = index * 4;
              buffer[bufferIndex + 0] = data1;
              buffer[bufferIndex + 1] = r * g * dna.dna12;
              buffer[bufferIndex + 2] = data3;
              buffer[bufferIndex + 3] = 255;
            }
          }
          
          self.postMessage({ buffer: buffer.buffer }, [buffer.buffer]);
        };
      `], { type: 'application/javascript' }))
    );

    let lastTime = 0;
    const targetFPS = 30;
    const frameInterval = 1000 / targetFPS;

    const animate = (currentTime: number) => {
      if (currentTime - lastTime < frameInterval) {
        requestAnimationFrame(animate);
        return;
      }
      lastTime = currentTime;

      // Pre-compute noise values
      const noiseValues = new Float32Array(width * height * 2);
      for (let x = 0; x < width; x++) {
        for (let y = 0; y < height; y++) {
          const index = (x + y * width) * 2;
          noiseValues[index] = noise3D(x / dna.dna1, y / dna.dna2, t / dna.dna9) * dna.dna5 + dna.dna7;
          noiseValues[index + 1] = noise3D(x / dna.dna3, y / dna.dna4, t / dna.dna10) * dna.dna6 + dna.dna8;
        }
      }

      workerRef.current?.postMessage({ width, height, noiseValues, dna }, [noiseValues.buffer]);
      t++;
      requestAnimationFrame(animate);
    };

    workerRef.current.onmessage = function(e) {
      const imageData = new ImageData(new Uint8ClampedArray(e.data.buffer), width, height);
      ctx.putImageData(imageData, 0, 0);
    };

    requestAnimationFrame(animate);

    return () => {
      workerRef.current?.terminate();
    };
  }, []);

  return (
    <div className="flex-grow flex justify-center">
      <canvas
        ref={canvasRef}
        className="w-1/2"
        style={{ border: "1px solid #fff" }}
      >
        Your browser does not support the HTML canvas tag.
      </canvas>
    </div>
  );
}