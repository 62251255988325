import { useEffect, useState, useMemo } from "react";
import { RangeSlider, TextInput } from "@mantine/core";
import { Filter } from "containers/Admin/PageCollectionAdminWip";

interface SliderLabelProps {
  formLabel?: string;
  onRangeValueEndChange?: (value: [string, string]) => void;
  filter: Filter;
  rangeValues: [string, string];
}

const RangeInput = ({ formLabel, onRangeValueEndChange, filter, rangeValues }: SliderLabelProps) => {
  const [rangeValue, setRangeValue] = useState<[number, number]>([0, 100]);
  const [displayValues, setDisplayValues] = useState<[string, string]>(rangeValues);

  const { step, precision, min, max } = useMemo(() => {
    const start = parseFloat(filter.numberFilter?.range.start?.toString() || "0");
    const end = parseFloat(filter.numberFilter?.range.end?.toString() || "100");

    const startDecimals = (start.toString().split('.')[1] || '').length;
    const endDecimals = (end.toString().split('.')[1] || '').length;
    const maxDecimals = Math.max(startDecimals, endDecimals, 1);

    const step = Math.pow(10, -maxDecimals);
    const precision = maxDecimals;

    return { step, precision, min: start, max: end };
  }, [filter.numberFilter?.range]);

  const roundToStep = (value: number) => {
    return Number(value.toFixed(precision));
  };

  useEffect(() => {
    setDisplayValues(rangeValues);
    const numericStart = Math.max(min, parseFloat(rangeValues[0]) || min);
    const numericEnd = Math.min(max, parseFloat(rangeValues[1]) || max);
    setRangeValue([roundToStep(numericStart), roundToStep(numericEnd)]);
  }, [rangeValues, min, max, precision]);

  const handleRangeValueChange = (value: [number, number]) => {
    const start = roundToStep(value[0]).toFixed(precision);
    const end = roundToStep(value[1]).toFixed(precision);

    const startUnit = rangeValues[0].replace(/[\d.]+/g, '').trim();
    const endUnit = rangeValues[1].replace(/[\d.]+/g, '').trim();

    const newDisplayValues: [string, string] = [
      `${start}${startUnit}`,
      `${end}${endUnit}`
    ];

    setRangeValue(value);
    setDisplayValues(newDisplayValues);
  };

  const handleRangeValueEndChange = (value: [number, number]) => {
    const start = roundToStep(value[0]).toFixed(precision);
    const end = roundToStep(value[1]).toFixed(precision);

    const startUnit = rangeValues[0].replace(/[\d.]+/g, '').trim();
    const endUnit = rangeValues[1].replace(/[\d.]+/g, '').trim();

    const newDisplayValues: [string, string] = [
      `${start}${startUnit}`,
      `${end}${endUnit}`
    ];

    if (onRangeValueEndChange) {
      onRangeValueEndChange(newDisplayValues);
    }
  };

  const handleInputChange = (index: number, value: string) => {
    const newDisplayValues = [...displayValues] as [string, string];
    newDisplayValues[index] = value;
    setDisplayValues(newDisplayValues);

    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      const newRangeValue = [...rangeValue] as [number, number];
      newRangeValue[index] = roundToStep(Math.max(min, Math.min(max, numericValue)));
      setRangeValue(newRangeValue);

      if (onRangeValueEndChange) {
        onRangeValueEndChange(newDisplayValues);
      }
    }
  };

  const trimLabel = (value: number) => {
    const formatted = value.toFixed(precision);
    return formatted.length > 8 ? formatted.slice(0, 7) + '…' : formatted;
  };

  return (
    <>
      <div className=" p-3 my-2">
        <div className="flex justify-between mb-2">
          <TextInput
            value={displayValues[0]}
            onChange={(e) => handleInputChange(0, e.target.value)}
            className="w-24"
            size={"xs"}
          />
          <TextInput
            value={displayValues[1]}
            onChange={(e) => handleInputChange(1, e.target.value)}
            className="w-24"
            size={"xs"}
          />
        </div>
        <RangeSlider
          value={rangeValue}
          onChange={handleRangeValueChange}
          onChangeEnd={handleRangeValueEndChange}
          min={min}
          max={max}
          step={step}
          precision={precision}
          label={trimLabel}
          minRange={step}
          size={7}
        />
        <div className="text-center content-center text-xs">{formLabel}</div>
      </div>
    </>
  );
};

export default RangeInput;