import React, { FC, useEffect, useId, useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory5 from "../CardCategory5/CardCategory5"
import catImage1  from "/public/images/categories/generative.webp"
import catImage2  from "public/images/categories/interactive.webp"
import catImage3 from "public/images/categories/evolving.webp"
import catImage4  from "public/images/categories/gaming.webp"
import catImage5  from "public/images/categories/pixel.webp"
import catImage6  from "public/images/categories/pfp.webp"
import catImage7 from "public/images/categories/dynamic.webp"
import catImage8  from "public/images/categories/audio.webp"


export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
}

const ntfsCatData = [
  { name: "Generative", image: "0d61e717-1e9b-436f-965b-435461903a0e_ButterflyEffect.png"  },
  { name: "Interactive", image: "c92b0419-c908-4eff-9965-f11111df8107_Dusk.PNG" },
  { name: "Pixel", image: "e0550a5b-ce38-4ad7-925a-e97a643cb83f_download (18).png" },
  { name: "Gaming", image: "4ebe12cb-24f8-41e2-85a7-9334345b458a_The Keymunity.jpg" },
  { name: "Evolving", image: "976b92f6-9464-48f0-a280-151f37ffa758_download (19).png" },
  { name: "PFP", image: "b87c315b-7595-4865-a466-b82aebb871d6_CP.png" },
];

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "Browse by category",
  subHeading = "Explore on-chain CNFTs by your choice of category",
  className = "",
  itemClassName = "",
}) => {
  const sliderRef = useRef(null);
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
    if (!sliderRef.current) {
      return;
    }

    const OPTIONS: Glide.Options = {
      perView: 4,
      gap: 32,
      bound: true,
      breakpoints: {
        1280: {
          perView: 4,
        },
        1024: {
          gap: 20,
          perView: 3.4,
        },
        768: {
          gap: 20,
          perView: 3,
        },
        640: {
          gap: 20,
          perView: 2.3,
        },
        500: {
          gap: 20,
          perView: 1.4,
        },
      },
    };

    let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
    slider.mount();
    // @ts-ignore
    return () => slider.destroy();
  }, [sliderRef, UNIQUE_CLASS]);

  return (
    <div className={`nc-SectionSliderCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`} ref={sliderRef}>
        <Heading desc={subHeading} hasNextPrev>
          {heading}
        </Heading>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {ntfsCatData.map((cat, index) => (
              <li key={index} className={`glide__slide ${itemClassName}`}>
                <CardCategory5
                  index={index}
                  image={cat.image}
                  name={cat.name}
                />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SectionSliderCategories;
