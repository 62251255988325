import React from "react";
//import { useLocation } from "react-router-dom";
//import HeaderLogged from "components/Header/HeaderLogged";
import Header2 from "components/Header/Header2";

const SiteHeader = () => {
  //let location = useLocation();
  // use login state here in future
  // return location.pathname === "/" ? <Header2 /> : <HeaderLogged />;
  return <Header2 />
};

export default SiteHeader;
