import React from "react";
import { Badge, Tooltip } from "flowbite-react";
import { LinkIcon } from "@heroicons/react/outline";

interface Props {
  tags?: string[];
  blend?: boolean;
  size?: "small" | "medium" | "large"; // Define size prop
}

const CollectionOnchain: React.FC<Props> = ({ tags = [], blend, size = "medium" }) => {
  const hasOnChainTag = tags.includes("On-Chain");

  // Define icon size classes based on the size prop
  const iconSizeClasses = {
    small: "w-4 h-4",
    medium: "w-6 h-6",
    large: "w-8 h-8",
  };

  const iconClasses = `z-20 cursor-pointer ${blend ? 'onchain-icon-color' : 'stroke-white'} ${iconSizeClasses[size]}`;

  return (
    <>
      {hasOnChainTag && (
        <Tooltip
          content="On-Chain Collection"
          placement="top"
          className="z-20 text-xs"
        >
          <LinkIcon
            className={iconClasses}
            aria-hidden="true"
          />
        </Tooltip>
      )}
    </>
  );
};

export default CollectionOnchain;
