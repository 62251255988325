
import { doGet } from 'helpers/api_helper';

export async function fetchSignedUrl(key: string): Promise<string> {
  try {
    const response = await doGet(`/api/media/cdn/${key}`);
    return response.data.signedUrl;
  } catch (error) {
    console.error('Error fetching signed URL:', error);
    throw error;
  }
}