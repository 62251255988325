import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import GeomBackground from "components/Ui/BgGeom";

const Privacy = () => {

    return (
        <>
            <Helmet>
                <title>Cardano Art - Privacy Policy</title>
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon h-24 2xl:h-28 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
                data-nc-id="HeadBackgroundCommon"
            >
                <GeomBackground
                    type="grid_small"
                    heading="Privacy Policy"
                />
            </div>
            <div className="container mt-12 space-y-16 lg:space-y-28 min-h-screen">
                <main>
                    <div className="max-w-5xl mx-auto p-6 space-y-6">
                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                Cardano Art ("we", "our", or "us") operates the website https://www.cardano.art/ (the "Site"). This Privacy Policy describes how we collect, use, and share information when you use our Site.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Information We Collect</h2>
                            <h3 className="text-xl font-semibold mt-4 mb-2">Non-Personal Information</h3>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                We do not collect personal information such as names, email addresses, or physical addresses. However, we may collect non-personal information about your use of the Site. This information includes, but is not limited to, your IP address, browser type, operating system, referring URLs, and other technical information.
                            </p>
                            <h3 className="text-xl font-semibold mt-4 mb-2">User Accounts</h3>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                When you connect your wallet to our Site, a user account is created. This account is linked solely to your wallet address. We do not collect any additional personal data.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Use of Information</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">The non-personal information we collect is used to:</p>
                            <ol className="list-decimal list-inside text-gray-700 dark:text-neutral-400  ml-4 space-y-1">
                                <li>Improve the performance and usability of our Site.</li>
                                <li>Analyse Site usage to enhance user experience.</li>
                                <li>Maintain the security and integrity of the Site.</li>
                            </ol>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Cookies and Tracking Technologies</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                We may use cookies and similar tracking technologies to collect non-personal information about your interactions with our Site. Cookies are small data files stored on your device that help us understand how you use our Site and improve your experience.
                            </p>
                            <p className="text-gray-700 dark:text-neutral-400  mt-2">
                                You can choose to disable cookies through your browser settings. However, disabling cookies may affect the functionality of the Site.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Third-Party Services</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                We may use third-party services to help operate our Site and analyse how our Site is used. These third parties may have access to non-personal information collected through cookies and other tracking technologies.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Security</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                We take reasonable measures to protect the information collected through our Site from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Changes to This Privacy Policy</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                We may update this Privacy Policy from time to time. When we do, we will revise the "Last updated" date at the top of this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Your Consent</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                By using our Site, you consent to our Privacy Policy.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                If you have any questions or concerns about this Privacy Policy, please contact us at hello@cardano.art.
                            </p>
                        </section>
                    </div>
                </main>
            </div >
        </>
    );
};

export default Privacy;