import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Asset, AssetData } from "../../helpers/types";

import LoadingSpinner from "components/LoadingSpinner";
import { Schema, createSchema } from 'genson-js';



import { doGet, doPost } from "helpers/api_helper";
import Input from "shared/Input/Input";
import FormItem from "components/FormItem";
import { useParams } from "react-router-dom";
import { Button, Table, Select, Checkbox } from "flowbite-react";
import { getAssetsFromCollectionFromBackend } from "helpers/helpers";
import BannerBreak from "components/BannerBreak";
import { Divider } from "@mantine/core";

export interface Filter {
  type: string,
  path: string,
  forceRange?: boolean,
  unit?: string,
  numberFilter?: {
    range: {
      start: number | string,
      end: number | string
    },
    selection?: {
      start: number | string,
      end: number | string
    }
  },
  stringFilter?: {
    options: string[],
    selection?: string[],
  }
}

const PageCollectionAdminWip: FC = () => {
  const [data, setData] = React.useState<Asset[]>([]);
  const [openTab, setOpenTab] = React.useState(1);
  const [assetItems, setAssetItems] = React.useState(1);
  const [isLoadingAPI, setIsLoadingAPI] = React.useState(true);
  const [isLoadingDB, setIsLoadingDB] = React.useState(false);
  const [id, setID] = React.useState("");
  const [policy, setPolicy] = React.useState("");
  const [assetName, setAssetName] = React.useState("");
  const [metadata, setMetadata] = React.useState({});
  const [metadataSchema, setMetadataSchema] = React.useState<Schema>({
    properties: {}
  });

  const [filters, setFilters] = React.useState<{ [key: string]: Filter }>({});

  const { path } = useParams();
  const [totalResults, setTotalResults] = React.useState<number>(0);
  const [assetsData, setAssetsData] = React.useState<AssetData[]>([]);


  console.log("ID", id, path);


  useEffect(() => {
    if (path == "") {
      return
    }
    const fetchCollectionExists = async () => {
      try {
        const response = await doGet(
          `/api/collection/path/${path}`
        );
        console.log(response.data);

        if (response.data.path) {
          setID(response.data.id);
          setPolicy(response.data.policy);

          const res = await doGet(
            `/api/collection/assets/${response.data.policy}?limit=1`
          );

          if (res.data.length) {
            setAssetName(res.data[0].name)
          }

        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoadingAPI(false);
      }
    };

    fetchCollectionExists();
  }, [path]);



  const loadMetadata = async () => {
    console.log(`/api/asset/${policy}/${Buffer.from(assetName).toString('hex')}`);

    const response = await doGet<any>(`/api/asset/${policy}/${Buffer.from(assetName).toString('hex')}`);

    const schema = createSchema(response.data.metadata, { noRequired: true })

    setMetadataSchema(schema)
  }

  const addToFilterTable = (name: string, path: string, t: string, add: boolean) => {
    if (add) {
      if (filters[name]) {
        return;
      }

      if (t === "string") {
        setFilters(prev => ({
          ...prev,
          [name]: {
            path,
            type: t,
            stringFilter: { options: ["---"] },
            forceRange: false
          }
        }));
      } else {
        setFilters(prev => ({
          ...prev,
          [name]: {
            path,
            type: t,
            numberFilter: { range: { start: 0, end: 100 } },
            forceRange: true
          }
        }));
      }
    } else {
      setFilters(prev => {
        const newFilters = { ...prev };
        delete newFilters[name];
        return newFilters;
      });
    }
  };

  const buildSchemaCreator = (data: Record<string, Schema> | undefined, path: string) => {
    if (!data) {
      return
    }
    const element = []

    for (const k of Object.keys(data)) {
      if (data[k].type != "array" && data[k].type != "object") {
        element.push(<div key={k}>
          <Checkbox style={{ margin: 5 }} onChange={(e) => addToFilterTable(k, path + k, data[k].type as string, e.target.checked)} />
          {k}: {data[k].type}
        </div>)

      } else {
        element.push(<div key={k}>{k}: {data[k].type}</div>)
      }

      if (data[k].type == "array") {
        if (data[k].items?.type == "object") {
          element.push(
            <div key={path + k + "[]."} style={{ paddingLeft: 25 }}>{buildSchemaCreator(data[k].items?.properties, path + k + "[].")}</div>
          )
        } else {
          element.push(
            <div key={path + k + "[]."} style={{ paddingLeft: 25 }}>
              <div>
                <Checkbox style={{ margin: 5 }} onChange={(e) => addToFilterTable(k, path + k + "[]", data[k].items?.type as string, e.target.checked)}></Checkbox>
                Items: {data[k].items?.type}
              </div>
            </div>
          )
        }
      }

      if (data[k].type == "object") {
        element.push(
          <div key={path + k + "."} style={{ paddingLeft: 25 }}>{buildSchemaCreator(data[k].properties, path + k + ".")}</div>
        )
      }
    }
    return (
      <div>
        {element}
      </div>
    )
  }

  const populateFilterValues = async () => {
    const res = await doPost(`/api/collection/${policy}/filtervalues`, filters, {});

    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      for (const key in res.data) {
        if (updatedFilters[key].forceRange || res.data[key].numberFilter) {
          const startValue = res.data[key].numberFilter.range.start.toString();
          const endValue = res.data[key].numberFilter.range.end.toString();
          const unit = startValue.replace(/[\d.-]/g, '').trim();

          updatedFilters[key] = {
            ...updatedFilters[key],
            numberFilter: res.data[key].numberFilter,
            unit: unit,
            type: 'number'
          };
        } else {
          updatedFilters[key] = {
            ...updatedFilters[key],
            stringFilter: res.data[key].stringFilter,
            type: 'string'
          };
        }
      }
      return updatedFilters;
    });
  };

  const saveFilterValues = async () => {
    const filtersToSave = Object.entries(filters).reduce((acc, [key, filter]) => {
      const { forceRange, unit, ...filterWithoutForceRange } = filter;
      if (forceRange || filter.numberFilter) {
        acc[key] = {
          ...filterWithoutForceRange,
          type: 'number',
          unit: unit || '',
          numberFilter: {
            range: {
              start: `${filter.numberFilter!.range.start}${unit || ''}`,
              end: `${filter.numberFilter!.range.end}${unit || ''}`
            },
            selection: {
              start: `${filter.numberFilter!.selection?.start || filter.numberFilter!.range.start}${unit || ''}`,
              end: `${filter.numberFilter!.selection?.end || filter.numberFilter!.range.end}${unit || ''}`
            }
          }
        };
      } else {
        acc[key] = { ...filterWithoutForceRange, type: 'string' };
      }
      return acc;
    }, {} as Record<string, Omit<Filter, 'forceRange'>>);

    const res = await doPost(`/api/collection/${policy}/filter`, filtersToSave, {});
    console.log("res", res.data);
  };

  console.log("meta", metadata);
  console.log("schema", metadataSchema);

  const creator = buildSchemaCreator(metadataSchema.properties, ".")

  // console.log(creator);
  // Fetch all assets to create sum for update total assets number
  const fetchAssetsData = async () => {
    try {
      const results = await getAssetsFromCollectionFromBackend(policy);
      setAssetsData(results as AssetData[]);
      setTotalResults(results.length);
      console.log("total assets pulled:" + totalResults)
    } catch (error) {
      console.error("Error fetching assets from external API:", error);
    }
  };

  const handleForceRangeChange = (filterKey: string) => {
    setFilters(prevFilters => {
      const updatedFilters = { ...prevFilters };
      const filter = { ...updatedFilters[filterKey] };

      filter.forceRange = !filter.forceRange;

      if (filter.forceRange) {
        // Convert to range filter
        const values = filter.stringFilter?.options.map(v => parseFloat(v)).filter(v => !isNaN(v)) || [];
        filter.numberFilter = {
          range: {
            start: Math.min(...values),
            end: Math.max(...values)
          },
          selection: {
            start: Math.min(...values),
            end: Math.max(...values)
          }
        };
        delete filter.stringFilter;
      } else {
        // Convert back to string filter
        filter.stringFilter = {
          options: filter.numberFilter ?
            [filter.numberFilter.range.start.toString(), filter.numberFilter.range.end.toString()] :
            ['0', '100']
        };
        delete filter.numberFilter;
      }

      updatedFilters[filterKey] = filter;
      return updatedFilters;
    });
  };

  const handleUnitChange = (filterKey: string, newUnit: string) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [filterKey]: {
        ...prevFilters[filterKey],
        unit: newUnit
      }
    }));
  };

  if (isLoadingDB) {
    return <LoadingSpinner />;
  } else {
    return (
      <>
        <Helmet>
          <title>Cardano Art - Admin Collection</title>
        </Helmet>
        {/* HEADER */}
        <BannerBreak />
        <div className="w-full min-h-screen">
          <div className="my-6 sm:lg:my-4 lg:my-6 max-w-7xl px-3 mx-auto space-y-2 sm:space-y-2">
            {/* HEADING */}
            <div className="">
              <h2 className="text-3xl sm:text-4xl font-semibold">
                Admin Configuration
              </h2>
              <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
                <Button.Group>
                  <Button size="xs" color="dark" className="" onClick={fetchAssetsData}>Update Assets Total</Button>
                  <Button size="xs" color="dark" className="" disabled>Placeholder Button</Button>
                  <Button size="xs" color="failure" className="" disabled>Delete Collection(wip)</Button>
                </Button.Group>
              </span>
            </div>
          </div>
          <div className="max-w-7xl p-3 mx-auto space-y-2 sm:space-y-2">
            <main>
              <Divider className="" />
              <h3 className="text-lg font-semibold py-4">
                Create Metadata Schema
              </h3>
              <div className="text-sm font-semibold">Asset Name:</div>
              <div className="flex items-start">
                <FormItem className="md:w-1/3 mr-4 ">
                  <Input
                    type="text"
                    name="name"
                    value={assetName}
                    onChange={(e) => setAssetName(e.target.value)}
                    required
                  />
                </FormItem>
                <Button color="dark" className="self-end" onClick={loadMetadata}>
                  Generate
                </Button>
              </div>
              <div className="text-sm font-semibold my-4">Schema:</div>
              <div className="px-2">
                <div className="">
                  {creator}
                </div>
              </div>
              <div>
                <div className="text-sm font-semibold my-4">Filters:</div>
                <div>
                  <Table>
                    <Table.Head>
                      <Table.HeadCell>Path</Table.HeadCell>
                      <Table.HeadCell>Values</Table.HeadCell>
                      <Table.HeadCell>Unit</Table.HeadCell>
                      <Table.HeadCell>Force Range</Table.HeadCell>
                    </Table.Head>
                    <Table.Body>
                      {Object.keys(filters).map(k => {
                        const filter = filters[k];
                        return (
                          <Table.Row key={k}>
                            <Table.Cell>{filter.path}</Table.Cell>
                            <Table.Cell>
                              {filter.numberFilter || filter.forceRange ? (
                                `start: ${filter.numberFilter?.range.start}, end: ${filter.numberFilter?.range.end}`
                              ) : (
                                <Select name="stringfilters" id="string-filters">
                                  {filter.stringFilter?.options.map((sf, i) => (
                                    <option key={i} value={sf}>{sf}</option>
                                  ))}
                                </Select>
                              )}
                            </Table.Cell>
                            <Table.Cell>
                              <Input
                                value={filter.unit || ''}
                                onChange={(e) => handleUnitChange(k, e.target.value)}
                                disabled={!filter.numberFilter && !filter.forceRange}
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <Checkbox
                                checked={filter.forceRange}
                                onChange={() => handleForceRangeChange(k)}
                              />
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                </div>
              </div>
              <Button.Group className="py-4">
                <Button color="dark" onClick={() => populateFilterValues()}>
                  Populate Filters
                </Button>
                <Button onClick={() => saveFilterValues()}>
                  Save Filter
                </Button>
              </Button.Group>
              <Divider className="my-2" />
            </main>
          </div>
        </div>
      </>
    );
  }
};

export default PageCollectionAdminWip;

