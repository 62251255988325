import React, {
  useState,
  useEffect,
  useRef,
  KeyboardEvent,
  ChangeEvent,
} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Input from "shared/Input/Input";
import collectionPng from "images/avatars/StellarProfile.webp";

import VerifyIcon from "components/VerifyIcon";
import { doGet } from "helpers/api_helper";
import Avatar from "shared/Avatar/Avatar";
import { getCollectionsFromBackend } from "helpers/helpers";

interface Collection {
  id: number;
  name: string;
  path: string;
  avatarpath: string;
  size: number;
  curated: boolean;
}

const SearchBar: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [collections, setCollections] = useState<Collection[]>([]);
  const [selectedResultIndex, setSelectedResultIndex] = useState<number>(0);
  const searchResultsRef = useRef<HTMLUListElement>(null);

  // Filter collections based on search query
  const filteredCollections = collections.filter((collection) =>
    collection.name.toLowerCase().includes(query.toLowerCase())
  );

  // Function to fetch all collections from API and update state
  // Function to fetch all collections from API and update state
  const fetchCollections = async (searchQuery: string) => {
    const response = await getCollectionsFromBackend(undefined, undefined, undefined, undefined, searchQuery);
    setCollections(response);
  };

  useEffect(() => {
    if (query.length >= 2) {
      fetchCollections(query);
    }
  }, [query]);

  // Function to handle search bar input change
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length >= 2) {
      setQuery(inputValue);
      setSelectedResultIndex(-1); // Reset the selected result index
    } else {
      setCollections([]);
      setQuery(inputValue);
    }
  };

  const handleResultSelect = (collection: Collection) => {
    setQuery(collection.name);
    setSelectedResultIndex(-1);
    window.location.href = `/collection/${collection.path}`;
  };

  // Event handler for keyboard navigation of search results
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault();
      setSelectedResultIndex((prevIndex) =>
        prevIndex < filteredCollections.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      setSelectedResultIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchResultsRef.current &&
        !searchResultsRef.current.contains(event.target as Node)
      ) {
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative">
      <Input
        type="search"
        className="pr-10 w-full"
        placeholder="Search collections"
        value={query}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
            const selectedCollection = filteredCollections[selectedResultIndex];
            handleResultSelect(selectedCollection);
          }
        }}
      />
      {query.length >= 2 && (
        <ul
          ref={searchResultsRef}
          className="absolute z-10 bg-white border rounded-md w-[150%] mt-1 dark:bg-slate-800 dark:text-white dark:border-black"
        >
          {filteredCollections.map((collection, index) => (
            <Link to={`/collection/${collection.path}`}>
              <li
                key={collection.id}
                className={`px-4 py-2 cursor-pointer ${
                  index === selectedResultIndex ? "bg-gray-200" : ""
                } hover:bg-gray-200 dark:hover:bg-gray-500`}
                onMouseDown={() => handleResultSelect(collection)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleResultSelect(collection);
                  }
                }}
                tabIndex={0}
              >
                <span className="text-neutral-900 dark:text-neutral-200 font-medium flex items-center">
                <Avatar
                    imgUrl={collection.avatarpath ? collection.avatarpath : ''}
                    sizeClass="h-9 w-9"
                    radius="rounded-full"
                    containerClassName="mr-2"
                  />
                  {collection.name}
                  {collection.curated ? <VerifyIcon iconClass="w-4 h-4" /> : null}

                  <span className="text-xs text-right text-primary-500 justify-end items-center ml-auto">
                    {collection.size} NFTs
                  </span>
                </span>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
