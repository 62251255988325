import React, { FC } from "react";

export interface PricesProps {
  className?: string;
  contentClass?: string;
  labelTextClassName?: string;
  labelText?: string;
  saleClass?: string;
  priceClass?: string;
  isListed: boolean;
  price: number;
}

const Prices: FC<PricesProps> = ({
  className = "pt-3",
  price = Math.floor(Math.random() * (999 - 10 + 1)) + 10,
  contentClass = "py-1.5 md:py-2 px-2.5 md:px-3.5 text-sm sm:text-base font-semibold",
  labelTextClassName = "bg-white",
  labelText = "Floor Price",
  saleClass = "border-green-500",
  priceClass = "text-green-500",
  isListed = false
}) => {
  if (isListed){
    labelText = "Price"
    saleClass = "border-green-500"
    priceClass = "text-green-500"
  } else {
    labelText = ""
    saleClass = "border-grey-500"
    priceClass = "text-grey-500"
  }
  

  return (
    <div className={`${className}`}>
      <div
        className={`flex items-baseline border-2 rounded-lg relative ${contentClass} ` + saleClass}
      >
        <span
          className={`block absolute font-normal bottom-full translate-y-1 p-1 -mx-1 text-xs text-neutral-500 dark:text-neutral-400 ${labelTextClassName}`}
        >
          {labelText}
        </span>
        <span className={`!leading-none` + priceClass }>{isListed ? `${Math.round(price)} ADA` : "Unlisted"}</span>
      </div>
    </div>
  );
};

export default Prices;
