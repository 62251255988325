import React, { FC } from "react";
import { Tooltip } from "flowbite-react";

export interface VerifyIconProps {
  className?: string;
  iconClass?: string;
}

const VerifyIcon: FC<VerifyIconProps> = ({
  className = "ml-1",
  iconClass = "w-5 h-5",
}) => {
  return (
    <span className={className}>
      <Tooltip
        content="Verified by Cardano Art"
        placement="top"
        className="z-20 text-xs"
      >
        <svg className={iconClass} viewBox="0 0 48 48" fill="none">
          <path
            d="M24 2l-18 8v12c0 11.11 7.67 21.47 18 24 10.33-2.53 18-12.89 18-24v-12l-18-8zm-4 32l-8-8 2.83-2.83 5.17 5.17 13.17-13.17 2.83 2.83-16 16z"
            fill="#38BDF8"
            stroke="#38BDF8"
          />
        </svg>
      </Tooltip>
    </span>
  );
};

export default VerifyIcon;
