import { useMotionValue } from 'framer-motion';
import React, { useState, useEffect } from 'react';
import { Collection } from "../../helpers/types";
import { generateRandomString } from '../../utils/generateRandomString';
import { CardPattern } from './CardPattern';
import NcImage from 'shared/NcImage/NcImage';

interface CardProps {
  cardpath?: string;
  name?: string;
  imageSize?: string;
}

const Card: React.FC<CardProps> = ({ cardpath, name, imageSize = '22rem' }) => {
  let mouseX = useMotionValue(0);
  let mouseY = useMotionValue(0);

  const [randomString, setRandomString] = useState('');

  useEffect(() => {
    let str = generateRandomString(1500);
    setRandomString(str);
  }, []);

  function onMouseMove({ currentTarget, clientX, clientY }: { currentTarget: any, clientX: any, clientY: any }) {
    let { left, top } = currentTarget.getBoundingClientRect();
    mouseX.set(clientX - left);
    mouseY.set(clientY - top);

    const str = generateRandomString(1500);
    setRandomString(str);
  }

  return (
    <div
      onMouseMove={onMouseMove}
      className={`group rounded-3xl w-full relative overflow-hidden bg-transparent flex items-center justify-center  ${imageSize === 'large' ? 'h-[22rem]' : 'h-[12rem]'}`}
    >
      <NcImage
        containerClassName="absolute inset-0 object-cover w-full"
        src={cardpath}
      />
      <CardPattern
        mouseX={mouseX}
        mouseY={mouseY}
        randomString={randomString}
      />
      <div className="relative z-10 flex items-center justify-center">
        <div className="relative h-24 w-60  rounded-full flex items-center justify-center text-white font-bold text-4xl">
          <div className="absolute w-full h-full bg-black/[0.8] blur-md rounded-xl" />
          <span className="text-white z-20 text-center text-3xl">{name}</span>
        </div>
      </div>
    </div>
  );
};
export const Icon = ({ className, ...rest }: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
      {...rest}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
    </svg>
  );
};

export default Card;