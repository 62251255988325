import CardLarge1 from "components/CardLarge1/CardLarge1";
import React, { FC, useState } from "react";

import { AssetData } from "../../helpers/types";
import { getAssetsFromCollectionFromBackendTest } from "../../helpers/helpers";

export interface SectionLargeSliderProps {
  className?: string;
  policy?: string;
}



const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  className = "",
  policy = "",
}) => {
  const [indexActive, setIndexActive] = useState(0);
  const [data, setData] = React.useState<AssetData[]>([]);


  React.useEffect(() => {
    if (!policy) {
      return;
    }
  
    const minPrice = 1;
    const maxPrice = 1000;
  
    getAssetsFromCollectionFromBackendTest(
      policy,
      undefined,
      undefined,
      undefined,
      [minPrice, maxPrice],
      undefined,
      undefined
    ).then((results) => {
      // Shuffle the results array to randomize the order
      const shuffledResults = shuffleArray(results as AssetData[]);
  
      setData(shuffledResults);
      console.log("results", shuffledResults);
    });
  }, [policy]);
  
  // Helper function to shuffle an array
  function shuffleArray<T>(array: T[]): T[] {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  }

  const handleClickNext = () => {
    setIndexActive((state) => {
      if (state >= 2) {
        return 0;
      }
      return state + 1;
    });
  };

  const handleClickPrev = () => {
    setIndexActive((state) => {
      if (state === 0) {
        return 2;
      }
      return state - 1;
    });
  };

  return (
    <div className={`nc-SectionLargeSlider relative ${className}`}>
      {data.slice(0, 3).map((asset, index) =>
        indexActive === index ? (
          <CardLarge1
            key={index}
            id={asset._id}
            image={Array.isArray(asset.metadata.image) ? asset.metadata.image.join("") : asset.metadata.image}
            name={asset.metadata.name}
            dbname={asset.name}
            src={asset.metadata.files && asset.metadata.files.length > 0 ? asset.metadata.files[0]?.src : asset.metadata.image}
            policy={policy}
            isShowing
            onClickNext={handleClickNext}
            onClickPrev={handleClickPrev}
          />
        ) : null
      )}
    </div>
  );
};

export default SectionLargeSlider;