import React, { FC, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Logo from "shared/Logo/Logo";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import { Button } from "flowbite-react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Navigation from "shared/Navigation/Navigation";
import SearchBar from "components/LiveSearch";
import { selectWallet, selectUser, logout } from "app/auth/login/reducer";
import { useAppDispatch, useAppSelector } from "app/hooks";
import NcDropDown, { NcDropDownItem } from "shared/NcDropDown/NcDropDown";

export interface MainNav2Props { }

const MainNav2: FC<MainNav2Props> = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const wallet = useAppSelector(selectWallet);
  const shortenedAddress = wallet.address && wallet.address.substring(0, 10) + "...";
  const user = useAppSelector(selectUser)

  const navigate = useNavigate();
  const dispatch = useAppDispatch()

  const handleLogout = () => {
    dispatch(logout())
    navigate('/');
  };

  const hanldeClickDropDown = (item: NcDropDownItem) => {
    if (!user) {  // check if id is there...... 
      navigate('/connect-wallet');
      return;
    }

    if (item.onClick) {
      item.onClick()
    }

    if (item.id === "view profile") {
      navigate(`/${user.name}`);
      return;
    }
    if (item.id === "edit") {
      navigate('/edit');
      return;
    }
    if (item.id === "admin") {
      navigate('/admin');
      return;
    }
    if (item.id === "edit profile") {
      navigate('/settings');
      return;
    }
    return;
  };

  const dropdownItems: NcDropDownItem[] = [
    { id: "view profile", name: "Profile", icon: "las la-user" },
    { id: "logout", name: "Logout", icon: "las la-sign-out-alt", onClick: handleLogout },
    { id: "edit profile", name: "User Settings", icon: "las la-cog" },
    { id: "notifications", name: "Notifications", icon: "las la-envelope-open" }
  ];

  if (!!user && user.role == "ADMIN") {
    dropdownItems.push({ id: "admin", name: "Admin", icon: "las la-envelope-open" })
  }

  return (
    <div className={`nc-MainNav2 relative z-10 ${"onTop "}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
          <div className="hidden sm:block flex-grow max-w-xs">
            <div className="relative">
              <SearchBar />
              <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
                <svg
                  className="h-5 w-5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 22L20 20"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="hidden items-center xl:flex space-x-2">
            <Navigation />
            <div className="hidden sm:block h-10 border-l border-neutral-300 dark:border-neutral-6000"></div>
            <SwitchDarkMode />
            {user && user.id ? (
              <NcDropDown
                data={dropdownItems}
                className="right-0"
                onClick={hanldeClickDropDown}
                renderTrigger={() => (
                  <Button gradientDuoTone="purpleToBlue">{shortenedAddress}</Button>
                )}
              />
            ) : (
              <Link
                to="/connect-wallet"
                className="hidden sm:block">
                <Button gradientDuoTone="purpleToBlue">
                  {"Connect Wallet"}
                </Button>
              </Link>
            )}
          </div>
          <div className="flex items-center space-x-1.5 xl:hidden">
            {user && user.id ? (
              <NcDropDown
                data={dropdownItems}
                className="right-0"
                onClick={hanldeClickDropDown}
                renderTrigger={() => (
                  <Button gradientDuoTone="purpleToBlue">{shortenedAddress}</Button>
                )}
              />
            ) : (
              <Link
                to="/connect-wallet"
                className="hidden sm:block">
                <Button gradientDuoTone="purpleToBlue">
                  {"Connect Wallet"}
                </Button>
              </Link>
            )}
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav2;

