import React, { useState, ReactElement } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { Button } from '@mantine/core';
import SideMenu from './SideMenu';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonOutline from 'shared/Button/ButtonOutline';
import RangeInput from './FilterComponents/RangeSlider';
import SelectTrait from './FilterComponents/SelectTrait';
import { Filter } from 'containers/Admin/PageCollectionAdminWip';

interface SideFiltersProps {
  onFilterChange: (name: string, filter: Filter) => void;
  filter: { [key: string]: Filter };
  applyFilter: () => void;
  resetFilter: () => void;
}

interface FilterElements {
  selectTraits: ReactElement[];
  rangeInputs: ReactElement[];
}

const SideFilters: React.FC<SideFiltersProps> = ({ filter, onFilterChange, applyFilter, resetFilter }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const handleRangeValueEndChange = (filterName: string, value: [string, string]) => {
    const f = { ...filter[filterName] };
    if (f.numberFilter) {
      f.numberFilter.selection = {
        start: value[0],
        end: value[1],
      };
      onFilterChange(filterName, f);
    }
  };

  const handleTraitsChange = (filterName: string, selectedValues: string[]) => {
    const updatedSearchParams = new URLSearchParams(location.search);

    if (selectedValues.length === 0) {
      const stringKey = ['metadata', 'string', ...filter[filterName].path.split('.')].join('.');
      updatedSearchParams.delete(stringKey);
    } else {
      const f = { ...filter[filterName] };
      f.stringFilter!.selection = selectedValues;
      onFilterChange(filterName, f);

      const stringKey = ['metadata', 'string', ...filter[filterName].path.split('.')].join('.');
      updatedSearchParams.set(stringKey, selectedValues.join(','));
    }

    // Preserve existing search parameters
    for (const [key, value] of searchParams.entries()) {
      if (!['metadata.string', 'metadata.range'].some(prefix => key.startsWith(prefix))) {
        updatedSearchParams.set(key, value);
      }
    }

    navigate(`?${updatedSearchParams.toString()}`, { replace: true });
  };

  const filterElements = Object.keys(filter).reduce<FilterElements>((acc, k, index) => {
    const bgClasses = [
      'bg-gray-200 dark:bg-slate-600',
      'bg-gray-200 dark:bg-slate-700',
    ];
    const bgClass = bgClasses[index % bgClasses.length];

    if (filter[k].stringFilter) {
      const stringKey = ['metadata', 'string', ...filter[k].path.split('.')].join('.');
      const selectedValues = searchParams.get(stringKey)?.split(',') || [];
      acc.selectTraits.push(
        <div key={k} className={`${bgClass} px-2 py-1 my-2 rounded-lg`}>
          <SelectTrait
            formLabel={k}
            filter={filter[k]}
            onChange={(t) => handleTraitsChange(k, t)}
            selectedValues={selectedValues}
          />
        </div>
      );
    } else if (filter[k].numberFilter) {
      const rangeKey = ['metadata', 'range', ...filter[k].path.split('.')].join('.');
      const rangeValues = searchParams.get(rangeKey)?.split(',') || [
        filter[k].numberFilter?.range.start?.toString() || '0',
        filter[k].numberFilter?.range.end?.toString() || '100',
      ];
      acc.rangeInputs.push(
        <div key={k} className={`${bgClass} my-2 rounded-lg`}>
          <RangeInput
            formLabel={k}
            onRangeValueEndChange={(v) => handleRangeValueEndChange(k, v)}
            filter={filter[k]}
            rangeValues={rangeValues as [string, string]}
          />
        </div>
      );
    }
    return acc;
  }, { selectTraits: [], rangeInputs: [] });

  return (
    <div>
      <span className="flex-shrink-0 text-right">
        <ButtonOutline
          className="w-auto !pr-16"
          sizeClass="pl-4 py-2.5 sm:pl-6"
          onClick={() => setIsMenuOpen(true)}
        >
          <svg
            className={`w-4 h-4 sm:w-6 sm:h-6`}
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.87 17.12L18.87 16.12"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="block truncate ml-2.5">Filter Metadata</span>
          <span className="absolute top-1/2 -translate-y-1/2 right-5">
            <ChevronLeftIcon
              className={`hidden md:block w-4 h-4 sm:w-5 sm:h-5 ${!isMenuOpen ? "rotate-180" : ""}`}
              aria-hidden="true"
            />
          </span>
        </ButtonOutline>
      </span>
      <SideMenu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        <div className="flex flex-row mb-4 content-center justify-center">
          <Button.Group className="gap-2">
            <ButtonPrimary className="m-0" onClick={resetFilter}>Reset Filters</ButtonPrimary>
          </Button.Group>
        </div>
        {filterElements.selectTraits}
        {filterElements.rangeInputs}
      </SideMenu>
    </div>
  );
};

export default SideFilters;