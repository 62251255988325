import React, { useState, useEffect } from "react";
import axios from "axios";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import FormItem from "components/FormItem";
import { Toast } from "flowbite-react";
import { BadgeCheckIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import MySwitch from "components/MySwitch";
import ImageUpload from "components/ImageUpload";
import SelectTags from "components/SelectTags";
import MediaUpload from "components/MediaUpload"
import { doPost } from "helpers/api_helper";
import GeomBackground from "components/Ui/BgGeom";
import NavMenu from "containers/Admin/NavMenu";

interface Collection {
  name: string;
  policy: string;
  avatarpath: string;
  bannerpath: string;
  description: string;
  discordpath: string;
  twitterpath: string;
  websitepath: string;
  cardtype: string;
  owner: string;
  ownerId: string;
  path: string;
  curated: boolean;
  tags: string;
}

interface TagsObject {
  tags: string[];
}

const initialState = {
  name: "",
  policy: "",
  avatarpath: "/s3",
  bannerpath: "/s3",
  description: "",
  discordpath: "",
  twitterpath: "",
  websitepath: "",
  owner: "",
  ownerId: "unclaimed",
  path: "",
  tags: "",
  cardtype: "",
  curated: false,
};

const PageCollectionCreate = () => {
  const [collection, setCollection] = useState<Collection>(initialState);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetKey, setResetKey] = useState(0);
  
  const [bannerImage, setBannerImage] = useState<File | null>(null);
  const [avatarImage, setAvatarImage] = useState<File | null>(null);
  const [cardMedia, setCardMedia] = useState<File | null>(null);
  const [previewType, setPreviewType] = useState<"image" | "video" | null>(null);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);


  const handleTagsChange = (selectedValues: string[]) => {
    setSelectedTags(selectedValues);
    console.log(selectedValues);
  };

  const handleBannerImageSelect = (file: File) => {
    setBannerImage(file);
  };

  const handleAvatarImageSelect = (file: File) => {
    setAvatarImage(file);
  };

  const handleCardMediaSelect = (file: File) => {
    setCardMedia(file);
    const isImage = file.type.startsWith("image/");
    const isVideo = file.type.startsWith("video/");
    if (isImage) {
      setPreviewType("image");
    } else if (isVideo) {
      setPreviewType("video");
    } else {
      setPreviewType(null);
    }
  };

  const urlRegex = /^((https?):\/\/)?([^\s/$.?#].[^\s]*)?$/;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    collection.curated = isCurated;
    setErrorMessage("");
    try {
      const formData = new FormData();
      if (bannerImage !== null) {
        formData.append("bannerImage", bannerImage);
      }

      if (avatarImage !== null) {
        formData.append("avatarImage", avatarImage);
      }

      if (cardMedia !== null) {
        formData.append("cardMedia", cardMedia);
      }

      if (
        (!urlRegex.test(collection.websitepath) &&
          collection.websitepath !== "") ||
        (!urlRegex.test(collection.twitterpath) &&
          collection.twitterpath !== "") ||
        (!urlRegex.test(collection.discordpath) &&
          collection.discordpath !== "")
      ) {
        setErrorMessage("Invalid URL format");
        return;
      }

      const tagsObject = { tags: selectedTags };

      formData.append("name", collection.name);
      formData.append("policy", collection.policy);
      formData.append("description", collection.description);
      formData.append("tags", JSON.stringify(tagsObject));
      formData.append("path", collection.path);
      formData.append("cardtype", previewType ?? "");
      formData.append("websitepath", collection.websitepath);
      formData.append("twitterpath", collection.twitterpath);
      formData.append("discordpath", collection.discordpath);
      formData.append("owner", collection.owner);
      formData.append("ownerId", collection.ownerId);

      console.log("ENV", process.env.NODE_ENV);

      await doPost("/api/collection/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }, true)

      console.log("Collection created successfully");
      setIsSubmitted(true);
      setCollection(initialState);
      setIsCurated(false);
      setErrorMessage("");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (error: any) {
      console.error(error);
      if (error.response) {
        const errorMessage: string = error.response.data.message;
        setErrorMessage(errorMessage);
      }
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setCollection({ ...collection, [name]: value });
  };

  const [isCurated, setIsCurated] = useState(false);

  const handleSwitchChange = (value: boolean) => {
    setIsCurated(value);
    console.log(isCurated);
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  return (
    <div className={`nc-PageUploadItem`} data-nc-id="PageUploadItem">
      <Helmet>
        <title>Cardano Art - Create Collection</title>
      </Helmet>
      <div
        className={`nc-HeadBackgroundCommon h-24 2xl:h-28 mt-12 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
        data-nc-id="HeadBackgroundCommon"
      >
        <NavMenu />
        <GeomBackground type="grid_small" heading="Create New Collection" subtitle="Set the default values of a new collection." />
      </div>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form
            onSubmit={handleSubmit}
            className="mt-1"
            encType="multipart/form-data"
          >
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
              {/* ---- */}
              <FormItem label="Collection Name">
                <Input
                  type="text"
                  name="name"
                  value={collection.name}
                  onChange={handleChange}
                  required
                />
              </FormItem>
              {/* ---- */}
              <FormItem label="Policy ID">
                <Input
                  type="text"
                  name="policy"
                  value={collection.policy}
                  onChange={handleChange}
                />
              </FormItem>
              {/* ---- */}
              <FormItem label="Description">
                <Textarea
                  name="description"
                  value={collection.description}
                  onChange={handleChange}
                  rows={6}
                  className="mt-1.5"
                  placeholder="..."
                />
              </FormItem>
              {/* ---- */}
              <FormItem label="Select Tags">
                <SelectTags onChange={handleTagsChange} />
              </FormItem>
              {/* ---- */}
              <FormItem
                label="Path Name"
                desc="The path name will define what the url will be E.G /collection/{path name}"
              >
                <Input
                  type="text"
                  name="path"
                  value={collection.path}
                  onChange={handleChange}
                  required
                />
              </FormItem>
              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
              <FormItem
                label="Card Image/Video"
                desc="Images and Videos allowed"
              >
                <MediaUpload
                  name="cardMedia"
                  onFileSelect={(file, type) => handleCardMediaSelect(file)}
                  key={`card-${resetKey}`}
                />
              </FormItem>
              <FormItem
                label="Banner Image"
                desc="Image files only, GIFs are allowed. Ideal size 1920 x 500"
              >
                <ImageUpload
                  name="bannerImage"
                  onFileSelect={(file) => handleBannerImageSelect(file)}
                  key={`banner-${resetKey}`}
                />
              </FormItem>
              <FormItem
                label="Avatar Image"
                desc="Image files only, GIFs are allowed. Ideal size 500 x 500"
              >
                <ImageUpload
                  name="avatarImage"
                  onFileSelect={(file) => handleAvatarImageSelect(file)}
                  key={`avatar-${resetKey}`}
                />
              </FormItem>
              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
              {/* ---- */}
              <FormItem label="Website Link">
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    https://
                  </span>
                  <Input
                    type="text"
                    name="websitepath"
                    value={collection.websitepath}
                    onChange={handleChange}
                    className="!rounded-l-none"
                    placeholder="website.com/"
                  />
                </div>
              </FormItem>
              {/* ---- */}
              <FormItem label="Twitter Link">
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    https://
                  </span>
                  <Input
                    type="text"
                    name="twitterpath"
                    value={collection.twitterpath}
                    onChange={handleChange}
                    className="!rounded-l-none"
                    placeholder="twitter.com/"
                  />
                </div>
              </FormItem>
              {/* ---- */}
              <FormItem label="Discord Link">
                <div className="flex">
                  <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    https://
                  </span>
                  <Input
                    type="text"
                    name="discordpath"
                    value={collection.discordpath}
                    onChange={handleChange}
                    className="!rounded-l-none"
                    placeholder="discord.com/"
                  />
                </div>
              </FormItem>
              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
              {/* ---- */}
              <FormItem label="Artist Display Name">
                <Input
                  type="text"
                  name="owner"
                  value={collection.owner}
                  onChange={handleChange}
                />
              </FormItem>
              {/* ---- */}
              <MySwitch
                label="Is the collection curated?"
                value={isCurated}
                onChange={handleSwitchChange}
                name="curated"
              />

              {/* ---- */}
              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1" type="submit">
                  Create Collection
                </ButtonPrimary>
              </div>
            </div>{" "}
          </form>
        </div>
        {isSubmitted && (
          <div className="fixed bottom-4 right-4 z-10">
            <Toast className="bg-green-500">
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                <BadgeCheckIcon className="h-5 w-5" />
              </div>
              <div className="ml-3 text-sm font-normal text-white px-3">
                Collection has been created.
              </div>
              <Toast.Toggle />
            </Toast>
          </div>
        )}
        {errorMessage && (
          <div className="fixed bottom-4 right-4 z-10">
            <Toast className="bg-red-500">
              <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-green-800 dark:text-red-200">
                <ExclamationCircleIcon className="h-5 w-5" />
              </div>
              <div className="ml-3 text-sm font-normal text-white px-3">
                {errorMessage}
              </div>
              <Toast.Toggle onClick={() => setErrorMessage("")} />
            </Toast>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageCollectionCreate;
