import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import GeomBackground from "components/Ui/BgGeom";

const Terms = () => {

    return (
        <>
            <Helmet>
                <title>Cardano Art - Terms & Cconditions</title>
            </Helmet>
            <div
                className={`nc-HeadBackgroundCommon h-24 2xl:h-28 left-0 right-0 w-full bg-primary-50 dark:bg-neutral-800/20 `}
                data-nc-id="HeadBackgroundCommon"
            >
                <GeomBackground
                    type="grid_small"
                    heading="Terms and Conditions"
                />
            </div>
            <div className="container mt-12 space-y-16 lg:space-y-28 min-h-screen">
                <main>
                    <div className="max-w-5xl mx-auto p-6 space-y-6">
                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Introduction</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                Welcome to Cardano Art (https://www.cardano.art/). These Terms and Conditions ("Terms") govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use our website.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Services</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                Cardano Art is a hub for on-chain and generative art NFT collections on the Cardano blockchain. Our website provides users with information and access to various NFT collections.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">User Accounts</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                To access certain features of our website, users may need to connect their wallet. By connecting your wallet, you create a user account on our website. We do not collect any personal data such as email addresses, phone numbers, or addresses. Your account is linked solely to your wallet.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Use of the Website</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">By using our website, you agree to the following:</p>
                            <ol className="list-decimal list-inside text-gray-700 dark:text-neutral-400  ml-4 space-y-1">
                                <li>You will use the website only for lawful purposes.</li>
                                <li>You will not use the website in any way that could damage or impair the website or interfere with other users' use of the website.</li>
                                <li>You will not attempt to gain unauthorized access to any part of the website or any systems or networks connected to the website.</li>
                            </ol>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Intellectual Property</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                All content on the Cardano Art website, including but not limited to text, graphics, logos, and images, is the property of Cardano Art or its content suppliers and is protected by copyright and other intellectual property laws. You may not reproduce, distribute, or create derivative works from any content on our website without our prior written permission.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Disclaimers</h2>
                            <ol className="list-decimal list-inside text-gray-700 dark:text-neutral-400  ml-4 space-y-1">
                                <li><strong>No Warranties</strong>: The website is provided on an "as is" and "as available" basis. We do not warrant that the website will be uninterrupted or error-free.</li>
                                <li><strong>No Financial Advice</strong>: The information provided on our website does not constitute financial advice. You should not rely on any information on the website to make any investment decisions.</li>
                            </ol>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Limitation of Liability</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                To the fullest extent permitted by law, Cardano Art shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use the website; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; and (c) any other matter related to the website.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Changes to These Terms</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                We may update these Terms from time to time. When we do, we will revise the "Last updated" date at the top of this page. We encourage you to review these Terms periodically to stay informed about our terms and conditions.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Governing Law</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                These Terms are governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles.
                            </p>
                        </section>

                        <section>
                            <h2 className="text-2xl font-semibold mb-2">Contact Us</h2>
                            <p className="text-gray-700 dark:text-neutral-400 ">
                                If you have any questions about these Terms, please contact us at hello@cardano.art.
                            </p>
                        </section>
                    </div>
                </main>
            </div >
        </>
    );
};

export default Terms;