import React, { Fragment, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import Radio from "shared/Radio/Radio";
import { ChevronDownIcon } from "@heroicons/react/outline";
import Input from "shared/Input/Input";
import { useLocation, useNavigate } from 'react-router-dom';


const typeOfSales = [
  {
    name: "Buy now",
  },
  {
    name: "On Auction",
  },
];

const sortOrderRadios = [
  { name: "Price low - high", id: "price:asc" },
  { name: "Price high - low", id: "price:desc" },
  { name: "Most liked", id: "likes:desc" },
];

interface TabFiltersProps {
  searchQuery: string;
  onSearchQueryChange: (query: string) => void;
  onPriceFilterChange: (priceRange: [number, number]) => void;
  selectedPriceFilterRange: [number, number];
  maxPriceFilterRange: [number, number];
  onSortOrderChange: (newSortOrder: string) => void;

  isOpenMobileFilter: boolean;
  closeModal: () => void;
}

const TabFilters: React.FC<TabFiltersProps> = ({
  searchQuery,
  onSearchQueryChange,
  selectedPriceFilterRange,
  maxPriceFilterRange,
  isOpenMobileFilter,
  closeModal,
}) => {

  // const [rangePrices, setRangePrices] = useState([0, 10000]);
  const [isOpenMoreFilter, setisOpenMoreFilter] = useState(isOpenMobileFilter);
  const [selectedPriceRange, setSelectedPriceRange] = useState<[number, number]>(
    selectedPriceFilterRange[1] === undefined ? [selectedPriceFilterRange[0], Infinity] : selectedPriceFilterRange
  );
  const [tempPriceRange, setTempPriceRange] = useState<[number, number]>(selectedPriceRange);
  const [selectedSortOrder, setSelectedSortOrder] = useState<string>('');


  const handleSearchChange = (searchValue: string) => {
    onSearchQueryChange(searchValue);
  };



  const handleSortOrderChange = (sortOrder: string) => {
    setSelectedSortOrder(sortOrder);

  };

  const location = useLocation();
  const navigate = useNavigate();

  const applyFilters = (
    defaultSortOrder = selectedSortOrder,
    defaultPriceRange = tempPriceRange,
    defaultSearchQuery = searchQuery
  ) => {
    const searchParams = new URLSearchParams(location.search);
    const priceRangeString = defaultPriceRange[1] === Infinity
      ? `${defaultPriceRange[0]},Infinity`
      : defaultPriceRange.join(',');
    searchParams.set('priceRange', priceRangeString);
    searchParams.set('sortBy', defaultSortOrder);
    searchParams.set('search', defaultSearchQuery);
    navigate(`?${searchParams.toString()}`);

    // Update the selectedPriceFilterRange state
    const [minPrice, maxPrice] = priceRangeString.split(',');
    setSelectedPriceRange([parseInt(minPrice), maxPrice === 'Infinity' ? Infinity : parseInt(maxPrice)]);
  };

  const resetPriceFilter = () => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('priceRange', maxPriceFilterRange.join(','));
    navigate(`?${searchParams.toString()}`);
    setTempPriceRange(maxPriceFilterRange);
  };

  const resetAllFilters = () => {
    setSelectedSortOrder("price:asc");
    setTempPriceRange([0, Infinity]);
    searchParams.set('search', "");
    applyFilters("price:asc", [0, Infinity], "");
  };

  const closeModalMoreFilter = () => {
    setisOpenMoreFilter(false);
    closeModal();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  // Yucky fix for mobile, tailwind not playing nice with the props and rendering.
  React.useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const searchParams = new URLSearchParams(location.search);
  const urlSortOrder = searchParams.get('sortBy') || '';

  React.useEffect(() => {
    if (urlSortOrder === 'price:asc' || urlSortOrder === 'price:desc' || urlSortOrder === 'likes:desc') {
      setSelectedSortOrder(urlSortOrder);
    } else {
      setSelectedSortOrder('price:asc');
    }
  }, [urlSortOrder]);






  // OK
  const renderXClear = () => {
    return (
      <span className="flex-shrink-0 w-4 h-4 l bg-gray-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  // OK
  const renderTabsSortOrder = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm mt-1 border-primary-500 focus:outline-none dark:text-primary-300 dark:border-primary-6000
              ${open ? "!border-primary-500 " : ""}
                ${!!selectedSortOrder.length
                  ? "!border-primary-500 "
                  : "border-neutral-300 dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                }
                `}
            >
              <svg className="w-4 h-4" viewBox="0 0 20 20" fill="none">
                <path
                  d="M11.5166 5.70834L14.0499 8.24168"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.5166 14.2917V5.70834"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48327 14.2917L5.94995 11.7583"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.48315 5.70834V14.2917"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.0001 18.3333C14.6025 18.3333 18.3334 14.6024 18.3334 10C18.3334 5.39763 14.6025 1.66667 10.0001 1.66667C5.39771 1.66667 1.66675 5.39763 1.66675 10C1.66675 14.6024 5.39771 18.3333 10.0001 18.3333Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {selectedSortOrder
                  ? sortOrderRadios.filter((i) => i.id === selectedSortOrder)[0]
                    .name
                  : "Sort order"}
              </span>
              {!selectedSortOrder.length ? (
                <ChevronDownIcon className="w-4 h-4 ml-3" />
              ) : (
                <span onClick={() => setSelectedSortOrder("")}>
                  {renderXClear()}
                </span>
              )}
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-md">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-5">
                    {sortOrderRadios.map((item) => (
                      <Radio
                        id={item.id}
                        key={item.id}
                        name="radioNameSort"
                        label={item.name}
                        defaultChecked={selectedSortOrder === item.id}
                        onChange={() => handleSortOrderChange(item.id)}
                      />
                    ))}
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        close();
                        setSelectedSortOrder("");
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        applyFilters();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  // OK
  const renderTabsPriceRage = () => {
    return (
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`flex items-center justify-center px-4 py-2 text-sm mt-1 border-primary-500  focus:outline-none dark:text-primary-300 dark:border-primary-6000`}
            >
              <svg
                className="w-4 h-4"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7 12H14"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <span className="ml-2">
                {selectedPriceFilterRange[0]} ADA - {selectedPriceFilterRange[1] !== Infinity ? `${selectedPriceFilterRange[1]} ADA` : 'No Limit'}
              </span>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-40 w-screen max-w-sm px-4 mt-3 left-0 sm:px-0 ">
                <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                  <div className="relative flex flex-col px-5 py-6 space-y-8">
                    <div className="space-y-5">
                      <span className="font-medium">Price range</span>
                      <Slider
                        range
                        min={1}
                        max={3000}
                        value={tempPriceRange}
                        step={1}
                        defaultValue={maxPriceFilterRange}
                        allowCross={false}
                        onChange={(_input: number | number[]) => setTempPriceRange(_input as [number, number])}
                      />
                    </div>

                    <div className="flex justify-between space-x-5">
                      <div>
                        <label
                          htmlFor="minPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Min price
                        </label>
                        <div className="mt-1 relative ">
                          <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                            ADA
                          </span>
                          <Input
                            type="number"
                            name="minPrice"
                            id="minPrice"
                            className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700  bg-transparent"
                            value={tempPriceRange[0]}
                            onChange={(e) => setTempPriceRange([parseInt(e.target.value), tempPriceRange[1]])}
                          />
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="maxPrice"
                          className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                        >
                          Max price
                        </label>
                        <div className="mt-1 relative rounded-md">
                          <span className="absolute inset-y-0 right-2 flex items-center pointer-events-none text-neutral-500 sm:text-sm">
                            ADA
                          </span>
                          <Input
                            type="number"
                            name="maxPrice"
                            id="maxPrice"
                            className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 bg-transparent"
                            value={tempPriceRange[1] !== Infinity ? tempPriceRange[1] : ''}
                            onChange={(e) => {
                              const value = e.target.value !== '' ? parseInt(e.target.value) : Infinity;
                              setTempPriceRange([tempPriceRange[0], value]);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        resetPriceFilter();
                        close();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        applyFilters();
                        close()
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  // FOR RESPONSIVE MOBILE
  const renderTabMobileFilter = () => {
    return (
      <div className="flex-shrink-0 hidden">
        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      NFTs filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto mt-3">
                    <div className="px-8 md:px-10  divide-neutral-200 dark:divide-neutral-800">
                      {/* --------- */}
                      <div className=" sm:block flex-grow max-w-xs">
                        <form className="relative" onSubmit={(e) => e.preventDefault()}>
                          <Input
                            type="search"
                            placeholder="Search items"
                            className="pr-10 w-full dark:"
                            sizeClass="h-[42px] pl-4 py-3"
                            value={searchQuery}
                            onChange={(e) => handleSearchChange(e.target.value)}
                          />
                          <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
                            <svg
                              className="h-5 w-5"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M22 22L20 20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                          <input type="submit" hidden value="" />
                        </form>
                      </div>
                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Range Prices</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                min={1}
                                max={3000}
                                value={tempPriceRange}
                                step={1}
                                defaultValue={maxPriceFilterRange}
                                allowCross={false}
                                onChange={(_input: number | number[]) => setTempPriceRange(_input as [number, number])}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min ADA
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                                  <Input
                                    type="number"
                                    name="minPrice"
                                    id="minPrice"
                                    className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 bg-transparent"
                                    value={tempPriceRange[0]}
                                    onChange={(e) => setTempPriceRange([parseInt(e.target.value), tempPriceRange[1]])}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max ADA
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"></div>
                                  <Input
                                    type="number"
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="block w-32 pr-10 pl-4 sm:text-sm border-neutral-200 dark:border-neutral-700 bg-transparent"
                                    value={tempPriceRange[1] !== Infinity ? tempPriceRange[1] : ''}
                                    onChange={(e) => {
                                      const value = e.target.value !== '' ? parseInt(e.target.value) : Infinity;
                                      setTempPriceRange([tempPriceRange[0], value]);
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* --------- */}
                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Sort Order</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-5">
                            {sortOrderRadios.map((item) => (
                              <Radio
                                id={item.id}
                                key={item.id}
                                name="radioNameSort"
                                label={item.name}
                                defaultChecked={selectedSortOrder === item.id}
                                onChange={() => handleSortOrderChange(item.id)}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        resetAllFilters();
                        closeModalMoreFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        applyFilters();
                        closeModalMoreFilter();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      {/* FOR DESKTOP */}
      <div className="hidden md:flex space-x-4">
        {renderTabsPriceRage()}
        {renderTabsSortOrder()}
        <form className="relative" onSubmit={(e) => e.preventDefault()}>
          <Input
            type="search"
            placeholder="Search items"
            className="pr-10 w-full"
            sizeClass="h-[42px] pl-4 py-3"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
          <span className="absolute top-1/2 -translate-y-1/2 right-3 text-neutral-500">
            <svg
              className="h-5 w-5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M22 22L20 20"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <input type="submit" hidden value="" />
        </form>
      </div>

      {/* FOR RESPONSIVE MOBILE */}
      <div className="flex overflow-x-auto lg:hidden space-x-4">
        {isMobile && renderTabMobileFilter()}
      </div>
    </div>
  );
};

export default TabFilters;
