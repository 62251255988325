import React, { FC, useRef, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

export interface NavProps {
  containerClassName?: string;
  className?: string;
  children?: React.ReactNode;
}

const Nav: FC<NavProps> = ({
  containerClassName = "",
  className = "text-black",
  children,
}) => {
  const navRef = useRef<HTMLDivElement>(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsMouseDown(true);
    setStartX(e.pageX - navRef.current!.offsetLeft);
    setScrollLeft(navRef.current!.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isMouseDown) return;
    const x = e.pageX - navRef.current!.offsetLeft;
    const walk = (x - startX) * 0.8;
    navRef.current!.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
  };

  const handleMouseLeave = () => {
    setIsMouseDown(false);
  };

  const handleScroll = () => {
    // To prevent scrolling by dragging when the mouse button is released
    if (isMouseDown) {
      setIsMouseDown(false);
    }
  };

  const scrollLeftFunc = () => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRightFunc = () => {
    if (navRef.current) {
      navRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  return (
    <nav
      className={`nc-Nav ${containerClassName}`}
      data-nc-id="Nav"
      style={{ overflow: "hidden" }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onScroll={handleScroll}
    >
      <style>
        {`
          .nc-Nav > div {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          .nc-Nav > div::-webkit-scrollbar {
            display: none; /* Hide scrollbar for WebKit (Chrome, Safari) */
          }
          .nc-Nav > div {
            -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
            scrollbar-width: none; /* Hide scrollbar for Firefox */
          }
        `}
      </style>
      <button onClick={scrollLeftFunc}>
        <ChevronLeftIcon className="h-5 w-5 mr-4" />
      </button>
      <div
        className={`flex overflow-x-auto ${className}`}
        ref={navRef}
      >
        {children}
      </div>
      <button onClick={scrollRightFunc}>
        <ChevronRightIcon className="h-5 w-5 mx-4" />
      </button>
    </nav>
  );
};

export default Nav;
