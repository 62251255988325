import React, { useState } from 'react';
import { UserContext } from './UserContext';
import { ColumnDef } from "@tanstack/react-table";
import { User } from "helpers/types";
import { MoreHorizontal, ArrowUpDown, } from "lucide-react"
import { Button } from "../../../components/Ui/Button"
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import {
    HamburgerMenuIcon,
    CheckIcon,
} from '@radix-ui/react-icons';
import { Link } from "react-router-dom";
import { deleteUser } from "../helpers";
import ActionCell from './ActionCell';



export const columns: ColumnDef<User>[] = [
    {
        id: "actions",
        cell: ({ row }) => <ActionCell row={row} />,
    },
    {
        accessorKey: "name",
        header: ({ column }) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Name
                    <ArrowUpDown className="ml-2 h-4 w-4" />
                </Button>
            )
        },
        cell: ({ row }) => (
            <div className="pl-4">
                {row.original.name}
            </div>
        ),
    },
    {
        accessorKey: "id",
        header: "UserID",
    },
    {
        accessorKey: "artist",
        header: "Artist",
    },
    {
        accessorKey: "role",
        header: () => <div className="">Role</div>,
        cell: ({ row }) => (
            <div className="font-medium">{row.original.role}</div>
        ),
    },
    {
        accessorKey: "created_at",
        header: "Created",
        cell: ({ row }) => {
            const createdAt = row.original.created_at ? new Date(row.original.created_at) : null;
            const formattedDate = createdAt ? createdAt.toISOString().split('T')[0] : '';
            return <div>{formattedDate}</div>;
        },
    },

];
